import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import store from './store'
import FloatingVue from 'floating-vue'
import i18n from './i18n'
import axios from './axios'
import Toast from 'vue-toastification'
import VueZoomer from 'vue-zoomer'

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import 'vue-toastification/dist/index.css'
import 'floating-vue/dist/style.css'
import VueTypedJs from 'vue-typed-js'
Vue.prototype.$http = axios

global.jQuery = require('jquery')
var $ = global.jQuery
window.$ = $

import '@/assets/scss/main.scss'
import 'promise-polyfill/src/polyfill'
import clickOutside from './directives/clickOutside.js'
import highlightInput from './directives/highlightInput.js'
import VueParallaxJs from 'vue-parallax-js'
import closeOnScroll from './directives/closeOnScroll.js';
import FloatingLabelInput from './components/shared/FloatingLabelInput.vue'

Vue.prototype.$http.defaults.withCredentials = true
const toastOptions = {
  timeout: 2500
}

Vue.directive('clickOutside', clickOutside)
Vue.directive('highlightInput', highlightInput)
Vue.directive('closeOnScroll', closeOnScroll);

Vue.use(VueTypedJs)
Vue.use(Toast, toastOptions)
Vue.use(FloatingVue)
Vue.use(VueParallaxJs)
Vue.use(VueZoomer)

Vue.component('VuePhoneNumberInput', VuePhoneNumberInput)
Vue.component('FloatingLabelInput', FloatingLabelInput)
new Vue({
  router: router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
