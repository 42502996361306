<template>
  <div class="cart-modal" @click.stop.prevent>
    <div class="cart-modal-overlay" @click="closeCartModal"></div>

    <div class="cart-modal-wrapper">
      <div class="cart-modal__content">
        <div class="cart-modal__columns">
          <!-- First Column -->
          <div class="cart-modal__first-column">
            <div class="cart-modal__first-column-header">
              <div class="cart-modal__first-column-left">
                <span class="cart-modal__header-cart">{{ $t('CART') }}</span>
                <span class="cart-modal__header-items">({{ cartContents.length }} {{ $t('items') }})</span>
              </div>
              <div class="cart-modal__clear-cart" @click="handleClearCartButton">
                {{ $t('Clear cart') }}
                <img src="@/assets/icons/trash-bin.svg" alt="Clear cart" />
              </div>
            </div>
            <div class="cart-modal__items-header">
              <div class="table-header-cell">{{ $t('Item') }}</div>
              <div class="table-header-cell">{{ $t('Price') }}</div>
            </div>
            <div class="cart-modal__items">
              <template v-for="(item, index) in cartContents">
                <div class="resp-table-row" :key="item.item.item_id">
                  <div class="table-body-cell cart-modal__item-info">
                    <img :src="imgDomain + item.item.img_url" alt="Item" class="cart-modal__item-image" />
                    <div>
                      <p class="cart-modal__item-info-weapon">
                        {{ getWeaponName(item.item.title) }}
                      </p>
                      <p class="cart-modal__item-info-name">
                        {{ getSkinName(item.item.title) }}
                      </p>
                      <p class="cart-modal__item-info-exterior">
                        {{ getExteriorFromTitle(item.item.title) }}
                      </p>
                    </div>
                  </div>
                  <div class="table-body-cell cart-modal__item-price">
                    <div class="cart-modal__item-price-text">
                      {{ currencySymbol }} {{ item.item.price.toFixed(2) }}
                    </div>
                    <img src="@/assets/icons/trash-bin.svg" alt="Remove" class="cart-modal__item-price-icon"
                      @click="removeFromCart({ item_id: item.item_id })" />
                  </div>
                </div>
                <div class="cart-modal__separator" :key="item.item.item_id" v-if="index !== cartContents.length - 1">
                </div>
              </template>
            </div>
          </div>
          <!-- Second Column -->
          <div class="cart-modal__second-column">
            <div class="cart-modal__summary">
              <span class="cart-modal__summary-title">{{ $t('Summary') }}</span>
              <div class="cart-modal__summary-row">
                <span class="cart-modal__summary-label">{{ $t('Items') }}</span>
                <span>{{ cartContents.length }}</span>
              </div>
              <div class="cart-modal__separator"></div>
              <div class="cart-modal__summary-row">
                <span class="cart-modal__summary-label">{{ $t('Total') }}</span>
                <span class="cart-modal__total-price">
                  {{ currencySymbol }} {{ totalSum }}
                </span>
              </div>
              <button class="cart-modal__checkout-button button" @click="switchToCheckout">
                {{ $t('Buy') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getExteriorFromTitle, getWeaponName, getSkinName } from '@/utils/helpers'
export default {
  name: 'CartModal',
  props: {
  },
  components: {},
  data: function () {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: '',
      pass: '',
      imgDomain: ''
    }
  },
  computed: {
    ...mapGetters('user', ['totalSum', 'cartContents']),
    ...mapGetters('app', ['currencySymbol'])
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN
  },
  methods: {
    ...mapActions('user', ['clearCart', 'removeFromCart']),
    ...mapActions('modals', ['toggleCartModal', 'closeCartModal']),
    getExteriorFromTitle,
    getWeaponName,
    getSkinName,
    switchToCheckout() {
      this.toggleCartModal()
      this.$router.push({ name: 'Checkout' })
    },
    switchToforgotPass() {
      this.$parent.clearError()
      setTimeout(() => {
        this.loginContentVisible = false
        this.forgotPassContentVisible = true
      }, 100)
    },
    switchToLogin() {
      this.$parent.clearError()
      setTimeout(() => {
        this.forgotPassContentVisible = false
        this.loginContentVisible = true
      }, 100)
    },
    submitLogin() {
      let loginData = {
        username: this.email,
        password: this.pass
      }
      this.$emit('login', loginData)
    },
    submitForgotPass() {
      let data = {
        email: this.email
      }
      this.$emit('forgotPass', data)
    },
    async handleClearCartButton() {
      const result = await this.clearCart()
      if (result === 'CLEAR_CART_ERROR') {
        this.$toast.error('Failed to clear cart. Please try again later.')
      } else {
        this.$toast.success('Cart cleared successfully')
      }
    }
  }
}
</script>


<style lang="scss" scoped>
.cart-modal {
  z-index: 2;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: unset;
  height: unset;
  right: 0;
  top: 60px;
  left: unset;
  box-shadow: 0 14px 20px rgba(19, 18, 20, 0.3);


  .cart-modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .cart-modal-wrapper {
    display: flex;
    flex-direction: column;
    background: #39383b;
    width: 631px;
    height: 326px;
  }

  .cart-modal__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .cart-modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 11px;
  }

  .cart-modal__columns {
    display: flex;
    height: 100%;
  }

  .cart-modal__first-column {
    flex: 0 1 409px;
    background: #39383b;
    display: flex;
    flex-direction: column;
    padding: 16px;

    overflow-y: auto;
    scrollbar-width: thin;

    ::-webkit-scrollbar {
      width: 8px;
    }


    .cart-modal__first-column-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      margin-bottom: 12px;

      .cart-modal__first-column-left {
        display: flex;
        align-items: center;
        justify-content: center;

        .cart-modal__header-cart {
          font-size: 18px;
          font-weight: 700;
        }

        .cart-modal__header-items {
          margin: 0 12px;
          color: #88868d;
          font-size: 14px;
        }
      }
    }

    .cart-modal__clear-cart {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: #88868d;

      img {
        margin-left: 8px;
        width: 12px;
        height: 12px;
      }
    }

    .cart-modal__items-header {
      display: flex;
      justify-content: space-between;
      color: #fff;
      margin-bottom: 12px;

      .table-header-cell {
        display: table-cell;
        padding: 10px 10px 10px 0;
        text-align: left;
        border-top: 1px solid #626165;
        border-bottom: 1px solid #626165;

        text-transform: uppercase;
        font-size: 14px;
        color: #88868D;

        &:first-child {
          flex: 0 1 256px;
        }

        flex: 1;
      }
    }

    .resp-table-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      border-bottom: 1px dashed #626165;
      padding-bottom: 12px;

      .table-body-cell {
        display: table-cell;
        flex: 1;

        &.cart-modal__item-info {
          display: flex;
          align-items: center;
          text-align: left;
          flex: 0 1 256px;

          .cart-modal__item-image {
            max-width: 64px;
            max-height: 48px;
            margin-right: 16px;
          }

          .cart-modal__item-info-weapon,
          .cart-modal__item-info-name,
          .cart-modal__item-info-exterior {
            color: #fff;
            margin: 0;
            font-size: 14px;
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 170px;
          }
        }

        &.cart-modal__item-price {
          display: flex;
          align-items: center;

          .cart-modal__item-price-text {
            margin-right: 8px;
            font-size: 14px;
            font-weight: 700;
          }

          .cart-modal__item-price-icon {
            cursor: pointer;
            margin-left: auto;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .cart-modal__second-column {
    border-radius: 4px 0 0 4px;
    flex: 0 1 221px;
    background: #2B2A2C;
    display: flex;
    flex-direction: column;
    padding: 17px 16px 24px 16px;

    .cart-modal__summary {
      height: 100%;
      color: #fff;
      display: flex;
      flex-direction: column;

      .cart-modal__summary-title {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 26px;
      }

      .cart-modal__summary-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 14px;

        .cart-modal__summary-label {
          font-size: 14px;
          line-height: 1.5;
          color: #88868D;
          text-transform: uppercase;
        }
      }

      .cart-modal__separator {
        border-bottom: 1px dashed #626165;
        margin-bottom: 14px;
      }

      .cart-modal__total-price {
        color: #90fc3c;
      }

      .cart-modal__checkout-button {
        margin-top: auto;
        padding: 15px 46px;
        width: 100%;
      }
    }
  }
}


@media (max-width: 768px) {
  .cart-modal {

    .cart-modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 4;
      cursor: pointer;
    }

    .cart-modal-wrapper {
      height: auto;
      position: fixed;
      top: 50px;
      transform: translateX(-50%);
      left: 50%;
      right: 0;
      width: 100%;
      overflow-y: auto;
      z-index: 6;

      .cart-modal__columns {
        flex-direction: column;
      }
    }
  }

}

// @media (max-width: 480px) {
//   .cart-modal {
//     top: 40px;
//   }

//   .cart-modal__first-column-header {
//     flex-direction: column;
//     align-items: flex-start;
//   }

//   .cart-modal__first-column-left {
//     margin-bottom: 8px;
//   }

//   .cart-modal__header-cart {
//     font-size: 16px;
//   }

//   .cart-modal__header-items {
//     font-size: 12px;
//   }

//   .cart-modal__clear-cart {
//     font-size: 12px;
//   }

//   .cart-modal__items-header .table-header-cell {
//     font-size: 12px;
//   }

//   .cart-modal__item-info-weapon,
//   .cart-modal__item-info-name,
//   .cart-modal__item-info-exterior {
//     font-size: 10px;
//   }

//   .cart-modal__item-price-text {
//     font-size: 10px;
//   }

//   .cart-modal__summary-title {
//     font-size: 10px;
//   }

//   .cart-modal__summary-label {
//     font-size: 10px;
//   }

//   .cart-modal__checkout-button {
//     padding: 10px 24px;
//     font-size: 12px;
//   }
// }</style>