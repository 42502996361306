<template>
    <main class="preview-product-list">
        <ProductListingPage />
    </main>
</template>

<script>
import ProductListingPage from '../pages/ProductListingPage.vue'

export default {
    name: 'PreviewProductList',
    components: {
        ProductListingPage
    },
    props: {
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>
.preview-product-list {
    max-width: 1280px; // Set the desired maximum width
    margin: 0 auto; // Center the component horizontally
}

::v-deep .main.page-inside {
    all: unset !important;

    .main-wrapper {
        all: unset !important;
    }

    .section.section.hero-section.product-list-section {
        all: unset !important;
    }

    .VuePagination {
        display: none;
    }
}
</style>