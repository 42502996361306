<template>
    <div class="payment-method">
        <div class="payment-method-box">
            <img src="@/assets/icons/credit-card.svg" alt="" />
        </div>
        <div class="payment-method-box" @click="togglePaymentMethodDropdown"
            v-clickOutside="closePaymentMethodDropdown">
            <div class="payment-method-dropdown" v-if="hasPaymentMethods">
                <span class="payment-method-value">{{ selectedPaymentMethod }}</span>
                <img src="@/assets/icons/chevron-down.svg" alt="Chevron Down" class="game-dropdown-icon"
                    :class="{ 'active': isPaymentMethodDropdownOpen }" />
                <div class="payment-method-options" v-if="isPaymentMethodDropdownOpen">
                    <div v-for="(paymentMethod, index) in paymentMethods" :key="index" class="payment-method-option"
                        @click="selectPaymentMethod(paymentMethod)">
                        {{ paymentMethod.title }}
                    </div>
                </div>
            </div>
            <div v-else class="no-payment-methods">
                {{ $t('No available payment methods') }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PaymentMethodDropdown",
    props: {
        paymentMethods: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isPaymentMethodDropdownOpen: false,
            selectedPaymentMethod: "",
        };
    },
    computed: {
        hasPaymentMethods() {
            return this.paymentMethods && this.paymentMethods.length > 0;
        }
    },
    mounted() {
        if (this.hasPaymentMethods) {
            this.selectedPaymentMethod = this.paymentMethods[0].title;
        } else {
            this.selectedPaymentMethod = this.$t('No available payment methods');
        }
    },
    methods: {
        closePaymentMethodDropdown() {
            this.isPaymentMethodDropdownOpen = false;
        },
        togglePaymentMethodDropdown() {
            if (this.hasPaymentMethods) {
                this.isPaymentMethodDropdownOpen = !this.isPaymentMethodDropdownOpen;
            }
        },
        selectPaymentMethod(paymentMethod) {
            this.selectedPaymentMethod = paymentMethod.title;
            this.isPaymentMethodDropdownOpen = false;
            this.$emit("selectPaymentMethod", paymentMethod);
        },
    },
};
</script>


<style lang="scss">
.payment-method {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #2B2A2C;
    border: 1px solid #626165;
    height: 50px;

    &-box {
        display: flex;
        justify-content: center;
        align-items: center;

        &:first-child {
            background-color: #626165;
            border-radius: 4px;
            width: 50px;
            height: 50px;
        }

        &:last-child {
            border-radius: 0px;
            position: relative;
            flex: 1 1 152px;
            flex-direction: column;
            justify-content: center;
            padding: 14px;
            align-items: flex-start;
            cursor: pointer;
        }
    }

    &-value {
        color: #fff;
        font-size: 14px;
        line-height: 1.5;
    }

    &-icon {
        position: absolute;
        top: 18px;
        right: 18px;
        width: 14px;
        height: 14px;
        filter: invert(39%) sepia(9%) saturate(145%) hue-rotate(214deg) brightness(94%) contrast(90%);

        &.active {
            transform: rotate(180deg);
            filter: invert(20%) sepia(62%) saturate(551%) hue-rotate(40deg) brightness(109%) contrast(105%);
        }
    }

    &-dropdown {
        &.open {
            // background-color: #626165;
            // border-radius: 4px 4px 0 0;
        }

        .payment-method-options {
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
            background-color: #39383b;
            border: 1px solid #626165;
            border-top: none;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            padding: 8px 0;
            z-index: 10;

            .payment-method-option {
                padding: 8px 16px;
                color: #fff;
                font-size: 14px;
                line-height: 1.5;
                cursor: pointer;

                &:hover {
                    background-color: #626165;
                    color: #fff;
                }

                &.selected {
                    background-color: #626165;
                    font-weight: bold;
                }
            }
        }
    }

    .no-payment-methods {
        color: #fff;
        font-size: 14px;
        line-height: 1.5;
    }
}
</style>