<template>
  <section class="carousel-section">
    <div class="carousel-wrapper">
      <VueSlickCarousel
        v-bind="settings"
        ref="carousel"
      >
        <CarouselProductCard
          v-for="item in list"
          :key="item.id"
          :item="item"
          :tag="tag"
          :addToCartChosenItem="addToCartChosenItem"
          :sliderIsClickble="sliderIsClickble"
        />
      </VueSlickCarousel>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'slick-carousel/slick/slick.css';
import CarouselProductCard from './CarouselProductCard.vue';
export default {
  components: {
    VueSlickCarousel,
    CarouselProductCard
  },
  props: ['list', 'tag', 'addToCartChosenItem', 'sliderIsClickble'],
  data() {
    return {
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        centerMode: true,
        variableWidth: true,
        centerPadding: '60px',
        speed: 500,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      }
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.carousel-section {
  width: 100%;
  overflow: hidden;
  padding: 40px 0;
}

.carousel-wrapper {
  position: relative;
  width: 100%;
}


::v-deep .slick-arrow {
  height: 50px;
  background: transparent;
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
  transition: 0.3s;
  background-image: url('~@/assets/icons/next.svg');
  background-size: 16px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 3;
  filter: brightness(0) saturate(100%) invert(99%) sepia(93%) saturate(2%) hue-rotate(254deg) brightness(108%) contrast(100%);
  padding: 0 30px;

  &:hover {
    opacity: 0.7;
  }

  &.slick-prev {
    left: 0;
    right: auto;
    transform: scaleX(-1) translateY(-50%);
  }

  &.slick-disabled {
    opacity: 0.2 !important;
    pointer-events: none;
  }
}
</style>