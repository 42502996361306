<template>
    <div class="modal sign-modal forgot-password-modal">
        <div class="overlay" @click="closeForgotPasswordModal"></div>
        <div class="wrapper">
            <div class="form">
                <div class="form-wrapper">
                    <img class="close" @click="closeForgotPasswordModal" src="@/assets/icons/close.svg" />
                    <div :class="['form-fields forgot-pass-content', { hidden: !forgotPassContentVisible }]">
                        <div class="form-fields-wrapper">
                            <div class="title">{{ $t("Forgot password") }}?</div>
                            <label>
                                <div class="desc">{{ $t("Email") }}:</div>
                                <input type="email" :placeholder="$t('Email')" v-model="email" />
                            </label>
                            <div class="actions-container">
                                <button class="button" @click="submitForgotPass">
                                    <span>{{ $t("Recover") }}</span>
                                </button>
                            </div>
                            <transition name="fade">
                                <div v-if="$parent.successMessage" class="desc green">{{ $parent.successMessage }}</div>
                            </transition>
                            <transition name="fade">
                                <div v-if="$parent.error" class="error-desc desc red">{{ $parent.error }}</div>
                            </transition>
                            <div class="modal-bottom">
                                <div class="desc">{{ $t("Have account") }}?</div>
                                <div class="link switch-login" @click="switchToLogin">{{ $t("Login") }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'ForgotPasswordModal',
    props: {},
    components: {},
    data: function () {
        return {
            forgotPassContentVisible: true,
            email: ''
        };
    },
    mounted() { },
    methods: {
        ...mapActions('modals', ['openLoginModal', 'closeForgotPasswordModal']),
        switchToLogin() {
            this.$parent.clearError();
            this.openLoginModal();
        },
        submitForgotPass() {
            let data = {
                email: this.email
            };
            this.$emit('forgotPass', data);
        }
    }
};
</script>

<style lang="scss" scoped>
.modal.sign-modal.forgot-password-modal {
    .overlay {
        // Styles for the overlay
    }

    .wrapper {

        // Styles for the wrapper
        .form {

            // Styles for the form
            .form-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;

                // Styles for the form wrapper
                .close {
                    // Styles for the close button
                }

                .form-fields {

                    // Styles for the forgot password content
                    .form-fields-wrapper {

                        // Styles for the form fields wrapper
                        .title {
                            margin-bottom: 29px;

                        }

                        label {
                            position: relative;

                            // Styles for the labels
                            .desc {
                                position: absolute;
                                left: 16px;
                                color: #88868d;
                                font-size: 12px;
                                line-height: 1.5;
                            }

                            input {
                                height: 50px;
                                background-color: transparent;
                                border: 1px solid #626165;
                                padding: 18px 15px 11px 16px;
                                color: #fff;
                                font-size: 14px;
                                line-height: 1.5;

                                &::placeholder {
                                    font-size: 14px;
                                    line-height: 1.5;
                                    color: #88868d;
                                }
                            }
                        }

                        .actions-container {

                            // Styles for the CTA container
                            .button {
                                margin-top: 26px;
                                width: 100%;
                                max-width: 304px;
                            }
                        }

                        .fade-enter-active,
                        .fade-leave-active {
                            // Styles for the fade transition
                        }

                        .desc.green {
                            // Styles for the success message
                        }
                    }

                    .modal-bottom {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 4px;
                        margin-top: 42px;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 1.5;

                        .link.switch-login {
                            cursor: pointer;
                            color: #4a88ff;

                            &:hover {
                                opacity: 0.7;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>