import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import modals from './modules/modals'
import staticPages from './modules/staticPages'
import productDetail from './modules/productDetail'
import country from './modules/country'
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    app,
    user,
    modals,
    staticPages,
    productDetail,
    country
  },
  strict: debug
})
