<template>
    <div class="modal">
        <div class="overlay" @click="closeAddPersonalInformationModal"></div>
        <div class="wrapper">
            <div class="form">
                <div class="form-wrapper">
                    <img class="close" @click="closeAddPersonalInformationModal" src="@/assets/icons/close.svg" />
                    <div class="form-fields">
                        <div class="title">{{ $t("Add Personal Information") }}</div>
                        <div class="form-fields-wrapper">
                            <FloatingLabelInput type="text" :placeholder="$t('First Name')" :label="$t('First Name')"
                                v-model="name" />
                            <FloatingLabelInput type="text" :placeholder="$t('Last Name')" :label="$t('Last Name')"
                                v-model="surname" />
                            <FloatingLabelInput type="email" :placeholder="$t('Email')" :label="$t('Email')"
                                v-model="email" />
                            <label v-if="countryOptionsIso && countryOptionsIso.length">
                                <div class="desc">{{ $t(`Phone`) }}</div>
                                <VuePhoneNumberInput v-model="phone" :only-countries="countryOptionsIso"
                                    :default-country-code="defaultCountryIso" @update="phoneDataUpdate($event)" />
                            </label>
                            <label>
                                <select v-model="country" :class="{ 'empty': !country }">
                                    <option disabled selected value="">{{ $t(`Country`) }}</option>
                                    <option v-for="(item) in countryOptions" :value="item" :key="item.id">
                                        {{ item.title }}
                                    </option>
                                </select>
                                <img src="@/assets/icons/close.svg" v-if="country" class="clear-icon"
                                    @click="() => this.country = ''" alt="close icon" />
                            </label>
                            <FloatingLabelInput type="text" :placeholder="$t('City')" :label="$t('City')"
                                v-model="city" />
                            <FloatingLabelInput type="text" :placeholder="$t('Address')" :label="$t('Address')"
                                v-model="address" />
                            <FloatingLabelInput type="text" :placeholder="$t('Zipcode')" :label="$t('Zipcode')"
                                v-model="zipcode" />
                            <FloatingLabelInput type="text" :placeholder="$t('Steam ID')" :label="$t('Steam ID')"
                                v-model="steamId" />
                        </div>
                        <div class="i-have-read-and-block">
                            <label class="checkbox-label">
                                <div class="checkbox-label-wrapper">
                                    <input type="checkbox" name="terms" v-model="terms" />
                                    <div class="checkbox"></div>
                                    <div class="checkbox-text-container">
                                        <span>{{ $t("I've read and accept the") }} </span>
                                        <a @click="goToPage('terms-and-conditions')"> {{ $t(`Terms of use`) }} </a>
                                        <span> {{ $t("and the") }} </span>
                                        <a @click="goToPage('privacy-policy')"> {{ $t(`Privacy Policy`) }} </a>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div class="cta-container">
                            <button class="button" @click="savePersonalInformation">
                                <span>{{ $t("Save") }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapState } from 'vuex/dist/vuex.common';

export default {
    name: 'AddPersonalInformationModal',
    mounted() {
        this.fetchCountries().then(() => {
            this.preselectUserData();
        });
    },
    data() {
        return {
            name: '',
            surname: '',
            email: '',
            phone: '',
            phoneFull: '',
            country: '',
            city: '',
            address: '',
            zipcode: '',
            steamId: '',
            terms: false,

            profilePhone: '',
            phoneCodes: [],

        }
    },
    computed: {
        ...mapState('user', ['user']),
        ...mapGetters('country', ['countryOptions', 'countryOptionsIso']),
        defaultCountryIso() {
            return this.user.phoneCountryCode || this.countryOptionsIso[0];
        },
    },
    components: {
    },
    methods: {
        ...mapActions('modals', ['closeAddPersonalInformationModal']),
        ...mapActions('user', ['fetchProfile']),
        ...mapActions('country', ['getCountries']),

        savePersonalInformation() {
            if (!this.terms) {
                this.$toast.error('You must accept the terms and conditions');
                return;
            }
            this.updateProfileData();
        },
        updateProfileData() {
            let profileData = {
                name: this.name,
                surname: this.surname,
                email: this.email,
                phone: this.phoneFull,
                country: this.country.id,
                city: this.city,
                address: this.address,
                postCode: this.zipcode,
                steamId: this.steamId,
            };

            this.$http
                .put(process.env.VUE_APP_API + 'user/profile', profileData)
                .then((res) => {
                    if (res.data.status == 'OK') {
                        this.$toast.success('Profile updated successfully');
                        this.closeAddPersonalInformationModal();
                        this.fetchProfile();
                    }
                })
                .catch((res) => {
                    if (res.response.status === 401) {
                        this.$parent.openSignInModal();
                    }
                    const errors = res.response.data.errors;
                    let errorMessages = [];
                    if (errors) {
                        Object.keys(errors).forEach((key) => {
                            errorMessages = errorMessages.concat(errors[key]);
                        });
                    } else {
                        errorMessages.push(res.response.data.message);
                    }
                    const maxErrorsToShow = 3;
                    errorMessages.slice(0, maxErrorsToShow).forEach((errorMessage) => {
                        this.$toast.error(errorMessage);
                    });
                });
        },
        goToPage(slug) {
            this.$emit('closeRegistrationModal');
            this.$router.push({ name: 'TextPage', params: { slug } });
        },
        async fetchCountries() {
            try {
                await this.getCountries();
            } catch (error) {
                if (error.status === 401) {
                    this.$parent.openSignInModal();
                } else {
                    console.error(error);
                }
            }
        },
        preselectUserData() {
            this.name = this.user.name || '';
            this.surname = this.user.surname || '';
            this.email = this.user.email || '';
            this.phone = this.user.phone || '';
            this.city = this.user.city || '';
            this.address = this.user.address || '';
            this.zipcode = this.user.zip || '';
            this.steamId = this.user.steamId || '';

            // Preselect the country if available
            if (this.user.country) {
                const userCountry = this.countryOptions.find(country => country.id === parseInt(this.user.country));
                if (userCountry) {
                    this.country = userCountry;
                }
            }
        },
        phoneDataUpdate(e) {
            this.phoneFull = e.formatInternational || e.phoneNumber;
        },
    }
}
</script>

<style lang="scss" scoped>
.modal {
    ::v-deep .vue-phone-number-input .select-country-container {
        flex: 0 1 60px;
        width: 60px;
        min-width: auto;
    }

    select {
        -moz-appearance: none;
        appearance: none;
        -webkit-appearance: none;
        height: 50px;
        background-color: transparent;
        border: 1px solid #626165;
        padding: 14px 15px 14px 16px;
        color: #fff;
        font-size: 14px;
        line-height: 1.5;
        width: 100%;
        cursor: pointer;

        &.empty {
            color: #88868d;
        }

        option {
            -webkit-appearance: none;
            height: 50px;
            background-color: #2B2A2C;
            border: 1px solid #626165;
            padding: 14px 15px 14px 16px;
            color: #fff;
            font-size: 14px;
            line-height: 1.5;
            width: 100%;
        }
    }

    .clear-icon {
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        color: white;
        font-size: 12px;
        width: 12px;
        height: 12px;
    }

    .clear-icon:hover {
        opacity: 0.6;
    }
}


.modal {
    .overlay {
        // Styles for the overlay
    }

    .wrapper {
        padding: 56px 44px;

        .form {
            .form-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;

                .close {
                    // Styles for the close button
                }

                .form-fields {
                    .title {
                        text-align: center;
                        text-transform: uppercase;
                        font-size: 32px;
                        font-weight: 700;
                        margin-bottom: 36px;
                    }

                    .form-fields-wrapper {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 12px 20px;
                        justify-items: center;
                        align-items: center;
                        justify-content: center;


                        label {
                            position: relative;
                            height: 50px;
                            display: inline-block;
                            width: 100%;


                            .desc {
                                position: absolute;
                                left: 16px;
                                color: #88868d;
                                font-size: 12px;
                                line-height: 1.5;
                                transition: top 0.3s, font-size 0.3s, color 0.3s;
                            }

                            input {
                                height: 50px;
                                background-color: transparent;
                                border: 1px solid #626165;
                                padding: 18px 15px 11px 16px;
                                color: #fff;
                                font-size: 14px;
                                line-height: 1.5;
                                width: 100%;

                                &::placeholder {
                                    font-size: 14px;
                                    line-height: 1.5;
                                    color: #88868d;
                                }

                                &:focus,
                                &:not(:placeholder-shown) {
                                    +.desc {
                                        top: 0;
                                        font-size: 12px;
                                        color: #90FC3C;
                                    }
                                }
                            }
                        }
                    }

                    .i-have-read-and-block {
                        margin-top: 36px;

                        .checkbox-label {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            .checkbox-label-wrapper {
                                display: flex;
                                align-items: center;
                                position: relative;
                                padding-left: 30px;

                                input[type="checkbox"] {
                                    position: absolute;
                                    opacity: 0;
                                    cursor: pointer;
                                    height: 0;
                                    width: 0;
                                    border-radius: 2px;

                                    &:checked {
                                        ~.checkbox {
                                            background-color: #90FC3C;
                                            border-color: transparent;

                                            &::after {
                                                opacity: 1;
                                                visibility: visible;
                                            }
                                        }
                                    }
                                }

                                .checkbox {
                                    margin-top: 1px;
                                    width: 18px;
                                    height: 18px;
                                    border: 1px solid #626165;
                                    border-radius: 2px;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    background-color: transparent;
                                    transition: background-color 0.3s, border-color 0.3s;

                                    &::after {
                                        content: '';
                                        width: 14px;
                                        height: 14px;
                                        position: absolute;
                                        left: 50%;
                                        top: 50%;
                                        transform: translate(-50%, -50%);
                                        background: url('~@/assets/icons/check-mark.svg') no-repeat center;
                                        background-size: contain;
                                        opacity: 0;
                                        visibility: hidden;
                                        filter: brightness(0);
                                        transition: opacity 0.3s, visibility 0.3s;
                                    }
                                }

                                .checkbox-text-container {
                                    line-height: 1.5;
                                    font-size: 14px;

                                    a {
                                        color: #4A87FF;

                                        &:hover {
                                            opacity: 0.5;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .cta-container {
                        margin-top: 26px;
                        text-align: center;

                        .button {
                            margin: 0 auto;
                            width: 100%;
                            max-width: 304px;
                            background-color: #90FC3C;
                            color: #131214;
                            font-size: 14px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:600px) {
    .modal .wrapper .form .form-wrapper .form-fields .form-fields-wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width:460px) {
    .form-fields-wrapper {
        flex-direction: column;
        align-items: center;
    }
}
</style>