<template>
  <div class="modal top-up-modal">
    <div class="overlay" @click="closeDepositModal"></div>
    <div class="wrapper">
      <div class="form">
        <div class="form-wrapper">
          <img class="close" @click="closeDepositModal" src="@/assets/icons/close.svg" />
          <div class="form-fields top-up-content">
            <div class="form-fields-wrapper">
              <div class="title">{{ $t("Top up balance") }}</div>
              <div class="label">{{ $t("Payment method") }}:</div>
              <PaymentMethodDropdown :paymentMethods="paymentMethods" @selectPaymentMethod="selectPaymentMethod" />
              <div class="label amount-label">{{ $t("Enter amount") }}</div>
              <label class="amount">
                <div class="desc">{{ currencySymbol }}</div>
                <input type="number" :placeholder="$t('Enter amount')" v-model="depositAmount" v-highlightInput />
              </label>
              <FloatingLabelInput type="email" :placeholder="$t('Email')" :label="$t('Email')" v-model="email" />
            </div>

            <div class="tooltip-wrapper">
              <div class="tooltip-content">
                <div class="tooltip-icon">
                  <img src="@/assets/icons/tooltip.svg" alt="Info" />
                </div>
                <div class="tooltip-text">
                  <p>
                    {{ $t(`Please note that funds received from deposits can only be spent on purchasing skins. It is
                    impossible to withdraw or transfer to another account.`) }}
                  </p>
                </div>
              </div>
            </div>

            <div class="form-fields-wrapper">
              <div class="cta-container">
                <div class="checkbox-container">
                  <label class="checkbox-label">
                    <div class="checkbox-label-wrapper">
                      <input type="checkbox" name="terms" v-model="terms" />
                      <div class="checkbox"></div>
                      <div class="checkbox-text-container">
                        <span>{{ $t("I've read and accept the") }} </span>
                        <a @click="goToPage('terms-and-conditions')">
                          {{ $t(`Terms of use`) }}
                        </a>
                        <span> {{ $t("and the") }} </span>
                        <a @click="goToPage('privacy-policy')">
                          {{ $t(`Privacy Policy`) }}
                        </a>
                      </div>
                    </div>
                  </label>
                </div>
                <button class="button" @click="submitDeposit">
                  <span>{{ $t("Pay") }}</span>
                </button>
              </div>
              <transition name="slide">
                <div v-if="$parent.error || error" class="error-desc desc red">
                  {{ $parent.error ? $parent.error : error }}
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import PaymentMethodDropdown from '../shared/PaymentMethodDropdown.vue'

export default {
  name: 'DepositModal',
  props: {
  },
  components: {
    PaymentMethodDropdown,
  },
  data: function () {
    return {
      name: '',
      surname: '',
      phone: '',
      steamId: '',
      country: '',
      city: '',
      address: '',
      postCode: '',
      email: '',
      terms: false,
      depositAmount: 0,
      error: null,

      countryOptions: [],
      nameValid: false,
      surnameValid: false,
      phoneValid: false,
      steamIdValid: false,
      countryValid: false,
      cityValid: false,
      addressValid: false,
      postCodeValid: false,
      emailValid: false,

      selectedPaymentMethod: null,

    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('app', ['currencySymbol', 'currencyCode']),

    currencyValid() {
      return this.currencyCode !== null
    },

    paymentMethods() {
      if (this.$parent.paymentMethods && this.currencyCode && this.currencyCode in this.$parent.paymentMethods) {
        return this.$parent.paymentMethods[this.currencyCode];
      }

      return this.$parent.paymentMethods;
    }
  },
  mounted() {
    this.getCountries()

    this.name = this?.user.name || ''
    this.surname = this?.user.surname || ''
    this.phone = this?.user.phone || ''
    this.steamId = this?.user.steamId || ''
    this.country = this?.user.country || ''
    this.city = this?.user.city || ''
    this.address = this?.user.address || ''
    this.postCode = this?.user.zip || ''
    this.email = this?.user.email || ''

    this.selectedPaymentMethod = this.paymentMethods ? this.paymentMethods[0].code : null;
  },
  beforeDestroy() {
    this.closeDepositModal()
  },
  methods: {
    ...mapActions('modals', ['closeDepositModal', 'openAddPersonalInformationModal', 'openTradeUrlModal']),


    selectPaymentMethod(paymentMethod) {
      this.selectedPaymentMethod = paymentMethod.code;
    },

    goToPage(slug) {
      this.$emit('closeRegistrationModal');
      this.$router.push({ name: 'TextPage', params: { slug } });
    },
    submitDeposit() {
      if (this.checkMissingUserInfo()) {
        this.closeDepositModal()
        this.openAddPersonalInformationModal();
        this.$toast.warning('Please fill in your personal information first')
        return;
      }

      // if (!this.user.tradeLink) {
      //   this.closeDepositModal()
      //   this.openTradeUrlModal();
      //   this.$toast.warning('Please set your Trade URL first')
      //   return;
      // }

      if (!this.terms) {
        this.error = this.$t("You must accept the terms and conditions.");
        return;
      }
      if (this.depositAmount <= 0 || this.depositAmount > 10000 || !Number.isInteger(Number(this.depositAmount))) {
        this.error = this.$t("The amount must be greater than zero and less than 10000");
        return;
      }

      if (!this.email) {
        this.error = this.$t("Email is required");
        return;
      }

      this.error = '';

      let data = {
        name: this.name,
        surname: this.surname,
        phone: this.phone,
        steamId: this.steamId,
        country: this.country,
        city: this.city,
        address: this.address,
        postCode: this.postCode,
        amount: this.depositAmount,
        email: this.user.email ? this.user.email : this.email,
      }
      this.processDeposit(data, this.selectedPaymentMethod)
    },
    getCountries() {
      this.$http
        .get(process.env.VUE_APP_API + 'countries')
        .then((res) => {
          this.countryOptions = res.data.payload
          this.countryOptionsIso = res.data.payload.map((obj) => obj.iso)
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openLoginModal()
          }
        })
    },
    checkMissingUserInfo() {
      return !this.user.name || !this.user.surname || !this.user.phone || !this.user.country || !this.user.city || !this.user.address || !this.user.zip;
    },
    processDeposit(data, type) {
      data.paymentType = type
      data.currency = this.currencyCode
      this.$http
        .post(process.env.VUE_APP_API + 'deposit/checkout', data)
        .then((res) => {
          if (res.data.status == 'ERROR') {
            Vue.$toast.error(res.data.message)
            this.$router.push({ name: 'PaymentFailPage' })
          } else {
            window.location = res.data.redirect_url
          }
        })
        .catch((res) => {
          Vue.$toast.error(res.response.data.message)
        })
    }
  }
}
</script>


<style lang="scss" scoped>
.modal.top-up-modal {

  .overlay {
    // Styles for the overlay
  }

  .wrapper {
    padding: 56px 0;
    // Styles for the wrapper

    .form {
      // Styles for the form

      .form-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        // Styles for the form wrapper

        .close {
          // Styles for the close button
        }

        .form-fields.top-up-content {
          width: 100%;

          .form-fields-wrapper {
            // padding: 0 130px;
            max-width: 304px;
            margin: 0 auto;

            .label {
              margin-bottom: 7px;
              font-size: 14px;
              line-height: 1.5;
              margin-top: 26px;
            }

            .title {
              text-align: center;
              text-transform: uppercase;
              font-size: 32px;
              font-weight: 700;
            }

            label {
              position: relative;
              height: 50px;
              display: inline-block;
              width: 100%;

              &+label {
                margin-top: 26px;
              }

              &.amount {
                .desc {
                  left: 16px;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #90FC3C;
                  font-size: 14px;
                  font-weight: 700;
                }

                input[type='number'] {
                  padding: 14px 14px 14px 41px;
                }
              }

              .desc {
                position: absolute;
                left: 16px;
                color: #88868d;
                font-size: 12px;
                line-height: 1.5;
              }


              input[type='email'],
              input[type='number'] {
                height: 50px;
                background-color: transparent;
                border: 1px solid #626165;
                padding: 18px 15px 11px 16px;
                color: #fff;
                font-size: 14px;
                line-height: 1.5;

                &::placeholder {
                  font-size: 14px;
                  line-height: 1.5;
                  color: #88868d;
                }
              }
            }

            .cta-container {
              margin-top: 24px;

              .checkbox-container {
                display: flex;
                align-items: center;
                margin-top: 15px;

                .checkbox-label {
                  display: flex;
                  align-items: center;
                  cursor: pointer;

                  .checkbox-label-wrapper {
                    display: flex;
                    align-items: center;
                    position: relative;
                    padding-left: 30px;

                    input[type="checkbox"] {
                      position: absolute;
                      opacity: 0;
                      cursor: pointer;
                      height: 0;
                      width: 0;
                      border-radius: 2px;

                      &:checked {
                        ~.checkbox {
                          background-color: $button-bg-color;
                          border-color: transparent;

                          &::after {
                            opacity: 1;
                            visibility: visible;
                          }
                        }
                      }
                    }

                    .checkbox {
                      margin-top: 1px;
                      width: 18px;
                      height: 18px;
                      border: 1px solid #626165;
                      border-radius: 2px;
                      position: absolute;
                      left: 0;
                      top: 0;
                      background-color: transparent;
                      transition: background-color 0.3s, border-color 0.3s;

                      &::after {
                        content: '';
                        width: 14px;
                        height: 14px;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        background: url('~@/assets/icons/check-mark.svg') no-repeat center;
                        background-size: contain;
                        opacity: 0;
                        visibility: hidden;
                        filter: brightness(0);
                        transition: opacity 0.3s, visibility 0.3s;
                      }
                    }

                    .checkbox-text-container {
                      line-height: 1.5;
                      font-size: 14px;

                      a {
                        color: #4A87FF;

                        &:hover {
                          opacity: 0.5;
                        }
                      }
                    }
                  }
                }
              }

              .button {
                margin-top: 26px;
                width: 100%;
                max-width: 304px;

                background-color: #90FC3C;
                color: #131214;

                font-size: 14px;
                font-weight: 700;

              }
            }

            .slide-enter-active,
            .slide-leave-active {
              // Styles for the slide transition
            }

            .error-desc.desc {
              text-align: center;
            }
          }
        }
      }

      .tooltip-wrapper {
        display: flex;
        align-items: center;
        max-width: 100%;
        width: 100%;
        margin: 16px 0px;
        height: 116px;
        background-color: #39383B;
        justify-content: center;
        align-content: center;

        .tooltip-content {
          max-width: 304px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          gap: 10px;

          .tooltip-icon {
            flex: 0 0 20px;

            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          .tooltip-text {
            font-size: 14px;
            color: #88868d;
            line-height: 1.5;
          }
        }
      }
    }
  }
}
</style>
