<template>
    <div class="product-currency" v-clickOutside="closeDropdown" @click="toggleCurrencyDropdown">
        <div class="product-currency-box">
            <div class="product-currency-symbol">{{ currency.symbol }}</div>
        </div>
        <div class="product-currency-box">
            <span class="product-currency-label">{{ $t('Currency:') }}</span>
            <div class="product-currency-dropdown" :class="{ 'open': isCurrencyDropdownOpen }">
                <span class="product-currency-value">{{ currency.code }}</span>
                <img src="@/assets/icons/chevron-down.svg" alt="Chevron Down" class="product-currency-icon"
                    :class="{ 'active': isCurrencyDropdownOpen }" />
                <div class="product-currency-options" v-if="isCurrencyDropdownOpen">
                    <div class="product-currency-option" v-for="currency in currencies" :key="currency.code"
                        @click="selectCurrency(currency)">
                        <div class="left">
                            <div class="product-currency-symbol">{{ currency.symbol }}</div>
                        </div>
                        <div class="right">
                            <span class="product-currency-value">{{ currency.code }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
    name: "ProductCurrencyDropdownMenu",
    props: [],
    data() {
        return {
            isCurrencyDropdownOpen: false,
        };
    },
    mounted() { },
    computed: {
        ...mapGetters('app', ['currencies', 'currency']),
    },
    methods: {
        ...mapMutations('app', ['setcurrency']),
        // ...mapActions('user', ['fetchProfile', 'fetchCartContents']),
        toggleCurrencyDropdown() {
            this.isCurrencyDropdownOpen = !this.isCurrencyDropdownOpen;
        },
        closeDropdown() {
            this.isCurrencyDropdownOpen = false;
        },
        selectCurrency(currency) {
            if (currency.code !== this.currencyCode) {
                this.setcurrency(currency);
                // this.fetchProfile();
                // this.fetchCartContents();
                this.isCurrencyDropdownOpen = false;
            } else {
                this.isCurrencyDropdownOpen = false;
            }
        },
    },
};
</script>

<style lang="scss">
.product-currency {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #39383b;
    border: 1px solid #626165;
    height: 50px;
    width: 100%;
    max-width: 202px;
    flex: 0 1 auto;
    cursor: pointer;

    &-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;

        &:first-child {
            background-color: #626165;
            border-radius: 4px;
            width: 50px;
            height: 50px;
            flex: 0 0 50px;
        }

        &:last-child {
            border-radius: 0px;
            flex: 1 1 152px;
            flex-direction: column;
            justify-content: center;
            padding: 0 18px 9px 14px;
            align-items: flex-start;
            
        }
    }

    &-symbol {
        color: $text-color-secondary;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5;
    }

    &-label {
        color: $text-color-description;
        font-size: 12px;
        line-height: 1.5;
    }

    &-value {
        color: #fff;
        font-size: 14px;
        line-height: 1.5;
    }

    &-icon {
        position: absolute;
        top: 18px;
        right: 18px;
        width: 14px;
        height: 14px;
        filter: invert(39%) sepia(9%) saturate(145%) hue-rotate(214deg) brightness(94%) contrast(90%);

        &.active {
            transform: rotate(180deg);
            filter: invert(20%) sepia(62%) saturate(551%) hue-rotate(40deg) brightness(109%) contrast(105%);
        }
    }

    &-dropdown {
        &.open {
            // background-color: #626165;
            // border-radius: 4px 4px 0 0;
        }

        .product-currency-options {
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
            background-color: #626165;
            border: 1px solid #626165;
            border-radius: 4px;
            margin-top: 2px;
            z-index: 10;

            .product-currency-option {
                display: flex;

                .left {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 0 0 auto;
                    border-radius: 4px;
                    width: 50px;
                    height: 50px;
                    flex: 0 0 50px;
                }

                .right {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 0 0 auto;
                    border-radius: 0px;
                    flex: 1 1 152px;
                    flex-direction: column;
                    justify-content: center;
                    padding: 0 18px 0px 14px;
                    align-items: flex-start;
                }

                &:hover {
                    border-radius: 4px;
                    background-color: #39383B;
                }
            }
        }
    }
}
</style>