export default {
  mounted(el, binding) {
    const handler = () => {
      if (binding.value) {
        binding.value()
      } else {
        el.style.display = 'none'
      }
    }

    window.addEventListener('scroll', handler)

    el.closeOnScrollHandler = handler
  },
  unmounted(el) {
    window.removeEventListener('scroll', el.closeOnScrollHandler)
  }
}
