<template>
    <label class="floating-label-input">
        <input :type="type" :placeholder="placeholder" :value="value" @input="$emit('input', $event.target.value)"
            :readonly="readonly" />
        <span class="floating-label">{{ label }}</span>
    </label>
</template>

<script>
export default {
    name: 'FloatingLabelInput',
    props: {
        type: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.floating-label-input {
    position: relative;
    height: 100%;
    display: inline-block;
    width: 100%;

    input {
        height: 50px;
        background-color: transparent;
        border: 1px solid #626165;
        padding: 18px 15px 14px 16px;
        color: #fff;
        font-size: 14px;
        line-height: 1.5;
        width: 100%;

        &::placeholder {
            color: transparent;
        }

        &:focus~.floating-label,
        &:not(:placeholder-shown)~.floating-label {
            top: 0px;
            font-size: 12px;
            transform: translateY(0);
        }

        &[readonly] {
            cursor: not-allowed;
        }
    }

    .floating-label {
        position: absolute;
        left: 16px;
        color: #88868d;
        font-size: 14px;
        line-height: 1.5;
        transition: 0.2s ease all;
        pointer-events: none;
        top: 50%;
        transform: translateY(-50%);
    }
}
</style>