<template>
    <section class="section breadcrumb-section">
        <div class="wrapper">
            <div class="breadcrumb">
                <template v-for="(item, index) in items">
                    <router-link :key="item.key" :to="item.to" class="breadcrumb-item">
                        {{ item.label }}
                    </router-link>
                    <span v-if="index < items.length - 1" :key="`separator-${index}`"
                        class="breadcrumb-separator">/</span>
                </template>
            </div>

        </div>
    </section>
</template>

<script>
export default {
    name: 'Breadcrumb',
    props: {
        items: {
            type: Array,
            required: true
        }
    },
}
</script>

<style scoped>
.section {
    margin-top: 24px;
    margin-bottom: 20px;
}

.breadcrumb {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
    flex-wrap: wrap;
    gap: 12px 0px;
}

.breadcrumb-item {
    color: #88868D;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.5;
    text-decoration: none;
    transition: all 0.3s;
}

.breadcrumb-item:hover {
    color: #90fc3c;
    opacity: 1;
}

.breadcrumb-separator {
    color: #88868D;
    margin: 0 8px;
}
</style>