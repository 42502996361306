<template>
  <div class="modal sign-modal change-email-modal">
    <div class="overlay" @click="closeChangeEmailModal"></div>
    <div class="wrapper">
      <div class="form">
        <div class="form-wrapper">
          <img class="close" @click="closeChangeEmailModal" src="@/assets/icons/close.svg" />
          <div class="form-fields login-content">
            <div class="form-fields-wrapper">
              <div class="title small">{{ $t('Change Email') }}</div>
              <label>
                <div class="description">{{ $t('Current Email') }}:</div>
                <input type="text" disabled :placeholder="$t('Current Email')" :value="user.email" required />
              </label>
              <label>
                <div class="description">{{ $t('New Email') }}:</div>
                <input type="text" :placeholder="$t('New Email')" v-model="newEmail" required />
                <div v-if="!newEmailValid" class="error-description description red">
                  {{ $t('New Email is required') }}
                </div>
              </label>
              <label>
                <div class="description">{{ $t('Password') }}:</div>
                <input type="password" :placeholder="$t('Password')" v-model="password" required />
                <div v-if="!passwordValid" class="error-description description red">
                  {{ $t('Password is required') }}
                </div>
              </label>
              <button class="button change-email-button" @click="handleChangeEmail">
                <span>{{ $t('Change Email') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Vue from 'vue'

export default {
  data() {
    return {
      newEmail: '',
      password: '',
      newEmailValid: true,
      passwordValid: true,
      formValid: true
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('modals', ['changeEmailModal'])
  },
  methods: {
    ...mapActions('user', ['changeEmail', 'logout']),
    ...mapActions('modals', ['closeChangeEmailModal']),
    async handleChangeEmail() {
      this.validateForm()
      if (this.formValid) {
        try {
          const response = await this.changeEmail({ email: this.newEmail, password: this.password })
          if (response.status === 'OK') {
            Vue.$toast.success(response.message)
            this.closeChangeEmailModal()
            this.logout()
          } else {
            Vue.$toast.error(response.message)
          }
        } catch (error) {
          if (error.response && error.response.status === 422) {
            Vue.$toast.error(error.response.message)
          } else {
            Vue.$toast.error('Failed to change email. Please try again later.')
          }
        }
      }
    },
    validateForm() {
      this.newEmailValid = this.newEmail.trim() !== ''
      this.passwordValid = this.password.trim() !== ''
      this.formValid = this.newEmailValid && this.passwordValid
    }
  }
}
</script>

<style lang="scss" scoped>
.modal.sign-modal.change-email-modal {
  .overlay {
    // Styles for the overlay
  }

  .wrapper {
    padding: 56px 0;

    // Styles for the wrapper
    .form {

      // Styles for the form
      .form-wrapper {

        // Styles for the form wrapper
        .close {
          // Styles for the close button
        }

        .form-fields {

          // Styles for the login content
          .form-fields-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            // Styles for the form fields wrapper
            .title {
              // Styles for the small title
              margin-bottom: 29px;
            }

            label {
              width: 100%;
              max-width: 304px;

              .description {
                font-size: 14px;
                font-weight: 700;
                color: #fff;
                margin-bottom: 8px;
              }

              input {
                width: 100%;
                padding: 15px 20px;
                background-color: #2a2a2a;
                border: 1px solid #4a4a4a;
                border-radius: 4px;
                color: #fff;
                font-size: 16px;
                font-weight: 400;

                &:disabled {
                  opacity: 0.4;
                }

                &::placeholder {
                  color: #88868d;
                }
              }

              &+label {
                margin-top: 12px;
              }

              .error-description {
                margin-top: 8px;
                color: #ff9090;
                font-size: 12px;
              }
            }

            .change-email-button {
              width: 100%;
              max-width: 304px;
              margin-top: 26px;
              flex: 0;
            }
          }
        }
      }
    }
  }
}
</style>