<template>
  <main class="main page-inside">
    <div class="main-wrapper">

      <Breadcrumbs :items="breadcrumbItems" />

      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="content">
            <div :class="['top', { active: filtersIsVisible }]">
              <div class="filters">
                <div class="filters-wrapper">

                  <GameDropdown :key="itemCategory.slug" :game-options="itemCategories" :initial-game="itemCategory"
                    @game-selected="onGameSelected" />

                  <div class="filters-price-container">
                    <div :class="['title small price', { active: !priceListIsVisible }]"
                      @click="priceListIsVisible = !priceListIsVisible">
                      {{ $t("Price") }}
                    </div>
                    <div class="price-range-container">
                      <div class="price-range-box">
                        <div class="desc">{{ $t(`From`) }}</div>
                        <div class="price-range-value">
                          <div class="currency">{{ currencySymbol }}</div>
                          <input type="number" v-model="barMinValue" v-highlightInput />
                        </div>
                      </div>
                      <div class="divider"></div>
                      <div class="price-range-box">
                        <div class="desc">{{ $t(`To`) }}</div>
                        <div class="price-range-value">
                          <div class="currency">{{ currencySymbol }}</div>
                          <input type="number" v-model="barMaxValue" v-highlightInput />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-for="(filter, index) in itemFilters" :key="index"
                    :class="['filter-container', filter.title.toLowerCase().replace(' ', '-') + '-container']">
                    <div :class="['title small', { active: filter.isVisible }]" @click="toggleFilterVisibility(index)">
                      {{ filter.title }}
                    </div>
                    <div :class="['select-container', { hidden: !filter.isVisible }]">
                      <label class="checkbox-label" v-for="(option, i) in filter.options" :key="i">
                        <div class="checkbox-label-wrapper">
                          <input type="radio" :name="filter.title" :value="option" v-model="filter.model"
                            @change="updateFilter(filter, option)" />
                          <div class="checkbox"></div>
                          <span class="title">{{ option }}</span>
                        </div>
                      </label>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div :class="['products-container', { active: !filtersIsVisible }]">
              <div class="filters-row">
                <div class="search-container">
                  <div class="left">
                    <label for="search" class="search-label">
                      <img class="search" src="@/assets/icons/search.svg" alt="Search" @click="filterProducts">
                      <input type="text" name="search" class="search-input" v-model="searchQuery"
                        :placeholder="$t('Search')">
                    </label>
                    <div class="clear-filters" @click="resetFilters">
                      <img class="close" src="@/assets/icons/trash-bin.svg" />
                      <span>{{ $t('Clear filters') }}</span>
                    </div>
                  </div>
                  <div class="right" v-clickOutside="closeSortDropdown">
                    <div class="sort-container" v-if="sortOptions.length">
                      <div class="sort-box" @click="toggleSortOrder">
                        <div class="sort-icon">
                          <img src="@/assets/icons/low-cost-first.svg" alt="Sort Icon"
                            :class="{ 'descending': sort === 'desc' }" />
                        </div>
                      </div>
                      <div class="sort-box" @click="toggleSortDropdown">
                        <div class="sort-dropdown">
                          <div class="sort-value">{{ currentSort }}</div>
                          <img src="@/assets/icons/chevron-down.svg" alt="Chevron Down" class="chevron"
                            :class="{ 'active': isSortDropdownOpen }" />
                          <div class="sort-options" v-if="isSortDropdownOpen">
                            <div class="sort-option" v-for="(option) in sortOptions" :key="option.value"
                              @click="selectSort(option)">
                              {{ option.label }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <transition name="fade">
                <div class="list products" v-if="productList.length > 0">
                  <div class="item" v-for="(item, i) in productList" :key="i">
                    <ProductCard :item="item" :sliderIsClickble="true" />
                  </div>
                </div>
                <div v-else class="no-items-message">
                  <p>{{ $t('No items found') }}</p>
                </div>
              </transition>
            </div>
          </div>
        </div>
        <div class="wrapper pagination">
          <Pagination v-if="totalProducts > 0" v-model="activePage" :records="totalProducts" :per-page="perPage"
            @paginate="changePage" :options="paginationOptions" />
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Pagination from "vue-pagination-2";
import Breadcrumbs from "../components/shared/Breadcrumbs.vue";
import ProductCard from "../components/ProductCard.vue";
// import { generateSlug } from "../utils/helpers";
// import Spinner from "../components/shared/Spinner.vue";
// import ImagePreviewModal from "../components/modals/ImagePreviewModal.vue";
import { mapGetters } from "vuex";
import _ from "lodash";
import GameDropdown from "../components/shared/GameDropdown.vue";
export default {
  name: "ProductListingPage",
  components: {
    Pagination,
    ProductCard,
    // Spinner,
    // ImagePreviewModal,
    GameDropdown,
    Breadcrumbs,
  },
  created() {
    this.initializePage();
  },
  data: function () {
    return {
      // this.itemCategory: this.itemCategory,
      // this.itemType: this.itemType,

      imgDomain: "",
      filtersIsVisible: true,
      totalProducts: 0,

      perPage: 15,
      activePage: 1,

      sortOptions: [
        { label: 'Low-cost first', value: 'asc' },
        { label: 'High-cost first', value: 'desc' },
      ],
      sort: "asc",
      currentSort: 'Low-cost first',

      itemType: "all",
      itemQuality: "all",
      itemHero: "all",
      itemExterior: "all",
      // itemClass: "all classes", //tf2
      searchQuery: "",

      itemFilters: [],

      // categoryOptions: {},
      // qualityOptions: {},
      barMinValue: 0,
      barMaxValue: 10000,
      productList: {},
      // typeListIsVisible: true,
      // qualityListIsVisible: false,
      priceListIsVisible: true,
      specialCategory: "",

      sortContainerRef: null,

      isDropdownOpen: false,
      isSearchInProgress: false,
      isRouteChangeInProgress: false,
      isSortDropdownOpen: false,


      // showImagePreviewModal: false,
      previewImageUrl: '',

      debouncedFilterProducts: null,

      itemCategories: [
        {
          name: 'CS2',
          slug: 'cs2',
          image: require('@/assets/icons/cs-logo.svg')
        },
        {
          name: 'DOTA 2',
          slug: 'dota2',
          image: require('@/assets/icons/dota-logo.svg')
        },
        {
          name: 'ALL',
          slug: 'all',
          image: require('@/assets/icons/dota-and-cs.svg')
        }
      ],
      itemCategory: {
        name: 'ALL',
        slug: 'all',
        image: require('@/assets/icons/dota-and-cs.svg')
      },

      paginationOptions: {
        format: true,
        chunk: 5,
        chunksNavigation: 'fixed',
        edgeNavigation: true,
        template: null,
        texts: {
          first: '1',
          last: String(this.totalPages),
          nextPage: '>',
          nextChunk: '>>',
          prevPage: '<',
          prevChunk: '<<'
        }
      },

      breadcrumbItems: [
        { label: 'Main', to: '/' },
        { label: 'Buy skins', to: '/products/all' }
      ],
    };
  },
  beforeRouteUpdate(to, from, next) {
    this.isRouteChangeInProgress = true;
    this.updateRouteParams(to.params);
    if (!to.params.itemType || (from.params.itemCategory != to.params.itemCategory)) {
      this.resetFilters();
    }
    else if (!from.params.itemType != to.params.itemType) {
      this.itemFilters.map(item => {
        if (item.slug == 'type') {
          item.model = to.params.itemType;
        }
      });
    }
    if (!to.params.itemCategory || !to.params.itemCategory == 'all') {
      this.itemCategory = this.itemCategories.find(category => category.slug === 'all');
      this.fetchFilterData();
    }

    this.updateBreadcrumbItems();

    this.$nextTick(async () => {
      this.filterProducts();
      this.isRouteChangeInProgress = false;
    });
    next();
  },


  computed: {
    ...mapGetters("app", ["currencySymbol", "currencyCode"]),

    totalPages() {
      return this.totalProducts > 0 ? Math.ceil(this.totalProducts / this.perPage) : 1;
    },
  },
  watch: {
    // this.itemCategory(){
    //   console.log(this.itemCategory);
    // },
    // $route() {
    //   this.checkRouteParams();
    // },
    totalProducts() {
      this.updatePaginationOptions();
    },
    currencyCode() {
      this.filterProducts();
    },
    searchQuery() {
      if (!this.isRouteChangeInProgress) {
        this.activePage = 1;
        this.debouncedFilterProducts();
      }
    },
    itemCategory(newCategory) {
      if (!this.isRouteChangeInProgress) {
        this.isRouteChangeInProgress = true;
        this.activePage = 1;
        this.$router.push({ name: 'ProductListingPage', params: { itemCategory: newCategory.slug, itemType: this.itemType } });
        this.updateBreadcrumbItems();
        this.$nextTick(async () => {
          await this.fetchFilterData();
          // this.filterProducts();
          this.isRouteChangeInProgress = false;
        });
      }
    },
    itemType(newType) {
      if (!this.isRouteChangeInProgress) {
        this.isRouteChangeInProgress = true;
        this.activePage = 1;
        this.$router.push({ name: 'ProductListingPage', params: { itemCategory: this.itemCategory.slug, itemType: newType } });
        this.updateBreadcrumbItems();
        this.$nextTick(() => {
          // this.filterProducts();
          this.isRouteChangeInProgress = false;
        });
      }
    },
    // quality() {
    //   if (!this.isRouteChangeInProgress) {
    //     this.activePage = 1;
    //     this.filterProducts();
    //   }
    // },
    barMinValue() {
      if (!this.isRouteChangeInProgress) {
        this.activePage = 1;
        this.debouncedFilterProducts();
      }
    },
    barMaxValue() {
      if (!this.isRouteChangeInProgress) {
        this.activePage = 1;
        this.debouncedFilterProducts();
      }
    },
    sort() {
      if (!this.isRouteChangeInProgress) {
        this.activePage = 1;
        this.filterProducts();
      }
    },
    // $route() {
    //   this.isRouteChangeInProgress = true;
    //   console.log(this.isRouteChangeInProgress);
    //   this.closeImagePreviewModal();
    //   this.resetFilters();
    //   this.checkRouteParams();
    //   this.isRouteChangeInProgress = false;
    // }
  },
  async mounted() {
    // await this.fetchFilterData()
    // this.debouncedFilterProducts = _.debounce(this.filterProducts, 300);
    // this.getQualityOptions();
    // this.checkRouteParams();
  },
  methods: {
    async initializePage() {
      this.setRouteParams();
      this.updateBreadcrumbItems();
      await this.fetchFilterData();
      this.debouncedFilterProducts = _.debounce(this.filterProducts, 300);
      this.filterProducts();
    },
    setRouteParams() {
      const { itemCategory, itemType } = this.$route.params;
      this.itemCategory = this.itemCategories.find(category => category.slug === itemCategory) || this.itemCategory;
      this.itemType = itemType || 'all';
    },
    updateRouteParams(params) {
      const { itemCategory, itemType } = params;
      this.itemCategory = this.itemCategories.find(category => category.slug === itemCategory) || this.itemCategory;
      this.itemType = itemType || 'all';
      this.updateBreadcrumbItems();
    },

    toggleFilterVisibility(index) {
      this.itemFilters[index].isVisible = !this.itemFilters[index].isVisible;
    },
    async fetchFilterData() {
      try {
        let url = process.env.VUE_APP_API + "items/filter-data";
        if (this.itemCategory && this.itemCategory.slug != 'all') {
          url += `?category=${this.itemCategory.slug}`;
        }
        const response = await fetch(url);
        const data = await response.json();
        if (data.status === 'OK') {
          this.initializeFilters(data.payload);
        }
      } catch (error) {
        console.error('Error fetching filter data:', error);
      }
    },
    initializeFilters(payload) {
      this.itemFilters = [];
      if (payload.types.length > 0) {
        this.itemFilters.push({ title: 'Types', options: ['all', ...payload.types.map(type => type.toLowerCase())], model: this.itemType, isVisible: false, slug: 'type' });
      }
      if (payload.qualities.length > 0) {
        this.itemFilters.push({ title: 'Qualities', options: ['all', ...payload.qualities], model: 'all', isVisible: false, slug: 'quality' });
      }
      if (payload.heroes.length > 0) {
        this.itemFilters.push({ title: 'Heroes', options: ['all', ...payload.heroes], model: 'all', isVisible: false, slug: 'heroes' });
      }
      if (payload.exterior_names.length > 0) {
        this.itemFilters.push({ title: 'Exterior Names', options: ['all', ...payload.exterior_names], model: 'all', isVisible: false, slug: 'exterior_names' });
      }
      if (payload.classes.length > 0) {
        this.itemFilters.push({ title: 'Classes', options: ['all', ...payload.classes], model: 'all', isVisible: false, slug: 'classes' });
      }
    },
    updateFilter(filter, option) {
      filter.model = option;
      if (filter.slug === 'type') {
        this.itemType = option;
      }
      this.activePage = 1;
      this.filterProducts();
    },
    updatePaginationOptions() {
      this.paginationOptions.texts.last = String(this.totalPages);
    },
    updateBreadcrumbItems() {
      this.breadcrumbItems = [
        { label: 'Main', to: '/' },
        { label: 'Buy skins', to: '/products/all' },
        { label: this.itemCategory.name, to: `/products/${this.itemCategory.slug}` },
      ];
      if (this.itemType !== 'all') {
        this.breadcrumbItems.push({ label: this.itemType, to: `/products/${this.itemCategory.slug}/${this.itemType}` });
      }
    },

    onGameSelected(selectedGame) {
      if (this.itemCategory.slug === selectedGame.slug) return
      this.itemCategory = selectedGame
    },
    // openImagePreviewModal(imageUrl) {
    //   this.showImagePreviewModal = true;
    //   this.previewImageUrl = imageUrl;
    // },
    // closeImagePreviewModal() {
    //   this.showImagePreviewModal = false;
    // },
    getPageTitle() {
      if (this.$route.params.pageType === "new-arrivals") {
        return "New Arrivals";
      } else if (this.$route.params.pageType === "sale") {
        return "Sale";
      } else {
        return "CS 2";
      }
    },
    // checkRouteParams() {
    //   this.isRouteChangeInProgress = true;
    //   this.activePage = 1;
    //   // Reset the breadcrumb items
    //   this.breadcrumbItems = [
    //     { label: 'Main', to: '/', key: 'main' },
    //     { label: 'Buy skins', to: '/products', key: 'buy-skins' },
    //   ];

    //   let itemCategoryRoute = this.itemCategory
    //   if (itemCategoryRoute && itemCategoryRoute !== 'all' && itemCategoryRoute != '') {
    //     this.itemCategory = this.itemCategories.find(category => category.slug === itemCategoryRoute);
    //     this.breadcrumbItems.push({
    //       label: this.itemCategory.name,
    //       to: `/products/${this.itemCategory.slug}`,
    //       key: this.itemCategory.slug,
    //     });
    //   }

    //   let itemTypeRoute = this.itemType
    //   if (itemTypeRoute && itemTypeRoute !== 'all') {
    //     this.itemType = this.itemFilters.find(filter => filter.slug === 'type').options.find(option => option === itemTypeRoute);
    //     this.updateFilter(this.itemFilters.find(filter => filter.slug === 'type'), itemTypeRoute);
    //     console.log(this.itemType, 'qwrwqr');
    //     this.breadcrumbItems.push({
    //       label: itemTypeRoute,
    //       to: `/products/${this.itemCategory.slug}/${itemTypeRoute}`,
    //       key: itemTypeRoute,
    //     });
    //   }

    //   this.$nextTick(() => {
    //     this.filterProducts();
    //     this.isRouteChangeInProgress = false;
    //   });
    // },
    resetFilters() {
      this.itemType = "all";
      this.itemFilters.forEach(filter => {
        filter.model = 'all';
      });

      this.$nextTick(() => {
        this.itemFilters.forEach(filter => {
          const radios = document.getElementsByName(filter.title);
          radios.forEach(radio => {
            radio.checked = radio.value === 'all';
          });
        });
      });


      this.barMinValue = 0;
      this.barMaxValue = 10000;
      this.searchQuery = "";
      this.sort = "asc";
      this.currentSort = "Low-cost first";
      this.isSortDropdownOpen = false;

      this.filterProducts();
    },
    filterProducts() {
      this.isSearchInProgress = true;

      const params = new URLSearchParams();

      // console.log(params.itemCategory, params.itemType);
      // Add category category
      if (this.itemCategory.slug !== 'all') {
        params.append('category', this.itemCategory.slug);
      }


      // Add selected filter values
      this.itemFilters.forEach(filter => {
        if (filter.model !== 'all') {
          params.append(filter.slug, filter.model);
        }
      });

      // Add price range
      params.append('price_from', this.barMinValue);
      params.append('price_till', this.barMaxValue);

      // Add sort order
      params.append('sort', this.sort);

      // Add pagination
      params.append('page', this.activePage);
      params.append('limit', this.perPage);

      // Add search query
      if (this.searchQuery) {
        params.append('search', this.searchQuery);
      }

      // Add currency
      params.append('currency', this.currencyCode);

      const queryString = params.toString();

      this.$http.get(`${process.env.VUE_APP_API}items/list?${queryString}`)
        .then((res) => {
          this.productList = res.data.payload;
          this.totalProducts = res.data.meta.total;
          this.pages = res.data.meta.total;
          this.isSearchInProgress = false;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
          this.isSearchInProgress = false;
        });
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },
    toggleSortDropdown() {
      this.isSortDropdownOpen = !this.isSortDropdownOpen;
    },
    toggleSortOrder() {
      this.sort = this.sort === 'asc' ? 'desc' : 'asc';
      this.currentSort = this.sort === 'asc' ? 'Low-cost first' : 'High-cost first';
    },
    selectSort(option) {
      this.currentSort = option.label;
      this.sort = option.value;
      this.isSortDropdownOpen = false;
    },
    closeSortDropdown() {
      this.isSortDropdownOpen = false;
    }

  },
};
</script>
