<template>
    <div class="game-dropdown" @click="toggleGameDropdown" v-clickOutside="closeDropdown">
        <div class="game-dropdown-box">
            <img v-if="selectedGameOption && selectedGameOption.image" :src="selectedGameOption.image"
                :alt="selectedGameOption.name" />
        </div>
        <div class="game-dropdown-box">
            <div class="game-dropdown-wrapper">
                <span class="game-name">{{ selectedGameOption && selectedGameOption.name }}</span>
                <img src="@/assets/icons/chevron-down.svg" alt="Chevron Down" class="game-dropdown-icon"
                    :class="{ 'active': isGameDropdownOpen }" />
            </div>
            <div class="game-dropdown-options" v-if="isGameDropdownOpen">
                <div class="game-option" v-for="option in gameOptions" :key="option.name" @click="selectGame(option)">
                    <div class="left">
                        <img v-if="option.image" :src="option.image" :alt="option.name" />
                    </div>
                    <div class="right">
                        <span class="game-name">{{ option.name }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'GameDropdown',
    props: {
        gameOptions: {
            type: Array,
            required: true
        },
        initialGame: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selectedGameOption: this.initialGame,
            isGameDropdownOpen: false
        }
    },
    methods: {
        toggleGameDropdown() {
            this.isGameDropdownOpen = !this.isGameDropdownOpen
        },
        closeDropdown() {
            this.isGameDropdownOpen = false
        },
        selectGame(option) {
            this.selectedGameOption = option
            this.$emit('game-selected', option)
            this.isGameDropdownOpen = false
        }
    }
}
</script>

<style lang="scss">
.game-dropdown {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #39383b;
    border: 1px solid #626165;
    height: 50px;
    width: 100%;
    max-width: 215px;
    flex: 0 1 auto;
    position: relative;
    cursor: pointer;

    &-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;

        &:first-child {
            background-color: #626165;
            border-radius: 4px;
            width: 50px;
            height: 50px;
            flex: 0 0 50px;
            padding: 5px;

            img {
                filter: invert(81%) sepia(57%) saturate(574%) hue-rotate(37deg) brightness(101%) contrast(98%);
                max-width: 100%;
                max-height: 100%;
            }
        }

        &:last-child {
            border-radius: 0px;
            flex: 1 1 152px;
            flex-direction: column;
            justify-content: center;
            padding: 14px 18px 14px 14px;
            align-items: flex-start;
        }
    }

    &-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
    }

    &-icon {
        position: absolute;
        top: 18px;
        right: 18px;
        width: 14px;
        height: 14px;
        filter: invert(39%) sepia(9%) saturate(145%) hue-rotate(214deg) brightness(94%) contrast(90%);

        &.active {
            transform: rotate(180deg);
            filter: invert(20%) sepia(62%) saturate(551%) hue-rotate(40deg) brightness(109%) contrast(105%);
        }
    }

    &-options {
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        border-radius: 4px;
        margin-top: 2px;
        background-color: #626165;
        border: 1px solid #626165;
        z-index: 10;

        .game-option {
            display: flex;

            .left {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 0 0 auto;
                border-radius: 4px;
                width: 50px;
                height: 50px;
                flex: 0 0 50px;

                img {
                    max-width: 36px;
                    max-height: 34px;
                    filter: invert(81%) sepia(57%) saturate(574%) hue-rotate(37deg) brightness(101%) contrast(98%);

                }
            }

            .right {
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 0 0 auto;
                border-radius: 0px;
                flex: 1 1 152px;
                flex-direction: column;
                justify-content: center;
                padding: 0 18px 0px 14px;
                align-items: flex-start;
            }

            &:hover {
                border-radius: 4px;
                background-color: #39383B;
            }
        }
    }

    .game-name {
        color: #fff;
        font-size: 14px;
        line-height: 1.5;
    }
}
</style>