<template>
    <div class="modal sign-modal registration-modal">
        <div class="overlay" @click="$emit('closeRegistrationModal')"></div>
        <div class="wrapper">
            <div class="container">
                <div class="form">
                    <div class="form-wrapper">
                        <img class="close" @click="$emit('closeRegistrationModal')" src="@/assets/icons/close.svg" />
                        <div class="form-fields register-content">
                            <div class="form-fields-wrapper">
                                <div class="title small">{{ $t(`CREATE ACCOUNT`) }}</div>
                                <FloatingLabelInput type="text" :placeholder="$t('First name')"
                                    :label="$t('First name')" v-model="name" />
                                <FloatingLabelInput type="text" :placeholder="$t('Last name')" :label="$t('Last name')"
                                    v-model="surname" />
                                <FloatingLabelInput type="email" :placeholder="$t('Email')" :label="$t('Email')"
                                    v-model="email" />
                                <label v-if="countryOptions && countryOptions.length">
                                    <div class="desc">{{ $t(`Phone`) }}</div>
                                    <VuePhoneNumberInput v-model="phone" :only-countries="countryOptions" :default-country-code="countryOptions[0]" @update="phoneDataUpdate($event)" />
                                </label>
                                <FloatingLabelInput type="password" :placeholder="$t('Password')"
                                    :label="$t('Password')" v-model="pass" />
                                <FloatingLabelInput type="password" :placeholder="$t('Confirm password')"
                                    :label="$t('Confirm password')" v-model="passConfirm" />
                                <div class="cta-container">
                                    <div class="checkbox-container">
                                        <label class="checkbox-label">
                                            <div class="checkbox-label-wrapper">
                                                <input type="checkbox" name="terms" v-model="terms" />
                                                <div class="checkbox"></div>
                                                <div class="checkbox-text-container">
                                                    <span>{{ $t("I've read and accept the") }} </span>
                                                    <a @click="goToPage('terms-and-conditions')">
                                                        {{ $t(`Terms of use`) }}
                                                    </a>
                                                    <span> {{ $t("and the") }} </span>
                                                    <a @click="goToPage('privacy-policy')">
                                                        {{ $t(`Privacy Policy`) }}
                                                    </a>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                    <button :class="['button']" @click="submitRegister">
                                        <span>{{ $t("Create Account") }}</span>
                                    </button>
                                </div>
                                <transition name="slide">
                                    <div v-if="$parent.error || error" class="error-desc desc red">
                                        {{ $parent.error ? $parent.error : error }}
                                    </div>
                                </transition>
                            </div>
                            <div class="modal-bottom">
                                <div class="desc">{{ $t("Have account") }}?</div>
                                <div class="link switch-login" @click="openLoginModal">{{ $t("Sign in") }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'RegistrationModal',
    props: [],
    components: {
    },
    data: function () {
        return {
            email: '',
            pass: '',
            name: '',
            surname: '',
            phone: '',
            passConfirm: '',
            terms: false,
            error: null,
            phoneFull: '',
            countryOptions: []
        };
    },
    mounted() {
        this.getCountries();
    },
    computed: {
        requiredRegisterFieldsAreFilled() {
            let fieldsAreFilled;
            if (this.name && this.surname && this.phone && this.email && this.pass && this.passConfirm && this.terms) {
                fieldsAreFilled = true;
            } else {
                fieldsAreFilled = false;
            }
            return fieldsAreFilled;
        }
    },
    methods: {
        goToPage(slug) {
            this.$emit('closeRegistrationModal');
            this.$router.push({ name: 'TextPage', params: { slug } });
        },
        getCountries() {
            this.$http
                .get(process.env.VUE_APP_API + 'countries')
                .then(res => {
                    this.countryOptions = res.data.payload.map(obj => obj.iso);
                })
                .catch(res => {
                    if (res.response.status === 401) {
                        this.$parent.openLoginModal();
                    }
                });
        },
        phoneDataUpdate(e) {
            this.phoneFull = e.formatInternational;
        },
        validateName() {
            let nameRegex = /^[a-zA-Z]+$/;
            if (!nameRegex.test(this.name)) {
                this.error = this.$t('The name field must only contain letters');
                return false;
            }
            return true;
        },
        validateSurname() {
            let surnameRegex = /^[a-zA-Z]+$/;
            if (!surnameRegex.test(this.surname)) {
                this.error = this.$t('The surname field must only contain letters');
                return false;
            }
            return true;
        },
        validateEmail() {
            let emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
            if (!emailRegex.test(this.email)) {
                this.error = this.$t('Please enter a valid email address');
                return false;
            }
            return true;
        },
        validatePassword() {
            let passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,}$/;
            if (!passwordRegex.test(this.pass)) {
                this.error = this.$t('The password must be at least 6 characters and include at least one uppercase letter, one lowercase letter, and one digit');
                return false;
            }
            return true;
        },
        validatePasswordConfirmation() {
            if (this.pass !== this.passConfirm) {
                this.error = this.$t('Password confirmation failed!');
                return false;
            }
            return true;
        },
        validateTerms() {
            if (!this.terms) {
                this.error = this.$t('You must agree to the terms and conditions to register');
                return false;
            }
            return true;
        },
        closeRegistrationModal() {
            this.$emit('closeLoginModal');
        },
        openLoginModal() {
            this.$emit('openLoginModal');
        },
        submitRegister() {
            this.$parent.clearError();
            if (
                !this.validateName() ||
                !this.validateSurname() ||
                !this.validateEmail() ||
                !this.validatePassword() ||
                !this.validatePasswordConfirmation() ||
                !this.validateTerms()
            ) {
                return;
            }
            this.error = '';
            let regData = {
                name: this.name,
                surname: this.surname,
                email: this.email,
                phone: this.phoneFull,
                password: this.pass,
                passConfirm: this.passConfirm
            };
            this.$emit('registration', regData);
        }
    }
};
</script>

<style lang="scss" scoped>
.modal.registration-modal {
    .overlay {
        // Styles for the overlay
    }

    .wrapper {

        // Styles for the wrapper
        .container {

            // Styles for the container
            .form {

                // Styles for the form
                .form-wrapper {

                    // Styles for the form wrapper
                    .close {
                        // Styles for the close button
                    }

                    .form-fields.register-content {

                        // Styles for the register content
                        .form-fields-wrapper {
                            display: flex;
                            flex-direction: column;
                            /* justify-content: center; */
                            align-items: center;

                            // Styles for the form fields wrapper
                            .title.small {
                                margin-bottom: 29px;
                            }

                            label {
                                // Styles for the labels
                                position: relative;
                                width: 100%;
                                max-width: 304px;

                                &+label {
                                    margin-top: 12px;
                                }

                                .desc {
                                    // Styles for the description
                                    position: absolute;
                                    left: 16px;
                                    color: #88868d;
                                    font-size: 12px;
                                    line-height: 1.5;
                                }

                                input {
                                    // Styles for the inputs
                                    height: 50px;
                                    background-color: transparent;
                                    border: 1px solid #626165;
                                    padding: 18px 15px 11px 16px;
                                    color: #fff;
                                    font-size: 14px;
                                    line-height: 1.5;

                                    &::placeholder {
                                        font-size: 14px;
                                        line-height: 1.5;
                                        color: #88868d;
                                    }
                                }
                            }

                            .checkbox-container {
                                display: flex;
                                align-items: center;
                                margin-top: 15px;

                                .checkbox-label {
                                    display: flex;
                                    align-items: center;
                                    cursor: pointer;

                                    .checkbox-label-wrapper {
                                        display: flex;
                                        align-items: center;
                                        position: relative;
                                        padding-left: 30px;

                                        input[type="checkbox"] {
                                            position: absolute;
                                            opacity: 0;
                                            cursor: pointer;
                                            height: 0;
                                            width: 0;
                                            border-radius: 2px;

                                            &:checked {
                                                ~.checkbox {
                                                    background-color: $button-bg-color;
                                                    border-color: transparent;

                                                    &::after {
                                                        opacity: 1;
                                                        visibility: visible;
                                                    }
                                                }
                                            }
                                        }

                                        .checkbox {
                                            margin-top: 1px;
                                            width: 18px;
                                            height: 18px;
                                            border: 1px solid #626165;
                                            border-radius: 2px;
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            background-color: transparent;
                                            transition: background-color 0.3s, border-color 0.3s;

                                            &::after {
                                                content: '';
                                                width: 14px;
                                                height: 14px;
                                                position: absolute;
                                                left: 50%;
                                                top: 50%;
                                                transform: translate(-50%, -50%);
                                                background: url('~@/assets/icons/check-mark.svg') no-repeat center;
                                                background-size: contain;
                                                opacity: 0;
                                                visibility: hidden;
                                                filter: brightness(0);
                                                transition: opacity 0.3s, visibility 0.3s;
                                            }
                                        }

                                        .checkbox-text-container {
                                            line-height: 1.5;
                                            font-size: 14px;

                                            a {
                                                color: #4A87FF;

                                                &:hover {
                                                    opacity: 0.5;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .button {
                                // Styles for the buttons
                                width: 100%;
                                max-width: 304px;
                                margin-top: 26px;
                            }

                            .slide-enter-active,
                            .slide-leave-active {
                                // Styles for the slide transition
                            }

                            .error-desc.desc.red {
                                // Styles for the error description
                            }
                        }

                        .modal-bottom {
                            // Styles for the modal bottom
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            gap: 4px;
                            margin-top: 42px;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 1.5;

                            .desc {
                                color: #fff;
                            }

                            .link {
                                cursor: pointer;
                                color: #4a88ff;

                                &:hover {
                                    opacity: 0.7;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>