const defaultState = function () {
  return {
    LoginModalVisible: false,
    ForgotPasswordModalVisible: false,
    RegistrationModalVisible: false,
    cartModalVisible: false,
    cookiesModalVisible: false,
    depositModalVisible: false,
    changeEmailModalVisible: false,
    tradeUrlModalVisible: false,
    addPersonalInformationModalVisible: false,
    mobileMenuVisible: false,
    bodyOverflow: 'auto'
  }
}

const state = defaultState()

const mutations = {
  setBodyOverflow(state, overflow) {
    state.bodyOverflow = overflow
    document.body.style.overflow = overflow
  },
  setRegistrationModalVisibility(state, visible) {
    state.RegistrationModalVisible = visible
  },
  setLoginModalVisibility(state, visible) {
    state.LoginModalVisible = visible
  },
  setForgotPasswordModalVisibility(state, visible) {
    state.ForgotPasswordModalVisible = visible
  },
  setCookiesModalVisibility(state, visible) {
    state.cookiesModalVisible = visible
  },
  setCartModalVisibility(state, visible) {
    state.cartModalVisible = visible
  },
  setDepositModalVisibility(state, visible) {
    state.depositModalVisible = visible
  },
  setChangeEmailModalVisibility(state, visible) {
    state.changeEmailModalVisible = visible
  },
  setTradeUrlModalVisibility(state, visible) {
    state.tradeUrlModalVisible = visible
  },
  setAddPersonalInformationModalVisibility(state, visible) {
    state.addPersonalInformationModalVisible = visible
  },
  setMobileMenuOpen(state, open) {
    state.mobileMenuVisible = open
  },

  resetState(state) {
    Object.assign(state, defaultState())
  }
}

const actions = {
  openRegistrationModal({ commit, dispatch }) {
    commit('setRegistrationModalVisibility', true)
    commit('setLoginModalVisibility', false)
    commit('setForgotPasswordModalVisibility', false)
    commit('setBodyOverflow', 'hidden')
    dispatch('clearUserError')
  },
  closeRegistrationModal({ commit }) {
    commit('setRegistrationModalVisibility', false)
    commit('setBodyOverflow', 'auto')
  },
  openLoginModal({ commit, dispatch }) {
    commit('setLoginModalVisibility', true)
    commit('setRegistrationModalVisibility', false)
    commit('setForgotPasswordModalVisibility', false)
    commit('setBodyOverflow', 'hidden')
    dispatch('clearUserError')
  },
  closeLoginModal({ commit }) {
    commit('setLoginModalVisibility', false)
    commit('setBodyOverflow', 'auto')
  },
  openForgotPasswordModal({ commit }) {
    commit('setForgotPasswordModalVisibility', true)
    commit('setLoginModalVisibility', false)
    commit('setBodyOverflow', 'hidden')
  },
  closeForgotPasswordModal({ commit }) {
    commit('setForgotPasswordModalVisibility', false)
    commit('setBodyOverflow', 'auto')
  },
  openCookiesModal({ commit }) {
    commit('setCookiesModalVisibility', true)
    // commit('setBodyOverflow', 'hidden')
  },
  closeCookiesModal({ commit }) {
    commit('setCookiesModalVisibility', false)
    // commit('setBodyOverflow', 'auto')
  },
  openDepositModal({ commit }) {
    commit('setDepositModalVisibility', true)
    commit('setBodyOverflow', 'hidden')
  },
  closeDepositModal({ commit }) {
    commit('setDepositModalVisibility', false)
    commit('setBodyOverflow', 'auto')
  },

  openChangeEmailModal({ commit }) {
    commit('setChangeEmailModalVisibility', true)
    commit('setBodyOverflow', 'hidden')
  },
  closeChangeEmailModal({ commit }) {
    commit('setChangeEmailModalVisibility', false)
    commit('setBodyOverflow', 'auto')
  },

  openTradeUrlModal({ commit }) {
    commit('setTradeUrlModalVisibility', true)
    commit('setBodyOverflow', 'hidden')
  },
  closeTradeUrlModal({ commit }) {
    commit('setTradeUrlModalVisibility', false)
    commit('setBodyOverflow', 'auto')
  },

  openAddPersonalInformationModal({ commit }) {
    commit('setAddPersonalInformationModalVisibility', true)
    commit('setBodyOverflow', 'hidden')
  },
  closeAddPersonalInformationModal({ commit }) {
    commit('setAddPersonalInformationModalVisibility', false)
    commit('setBodyOverflow', 'auto')
  },

  openCartModal({ commit }) {
    commit('setCartModalVisibility', true)
    commit('setBodyOverflow', 'hidden')
  },
  closeCartModal({ commit }) {
    commit('setCartModalVisibility', false)
    commit('setBodyOverflow', 'auto')
  },
  toggleCartModal({ commit, state }) {
    commit('setCartModalVisibility', !state.cartModalVisible)
    commit('setBodyOverflow', state.cartModalVisible ? 'hidden' : 'auto')
  },

  toggleMobileMenu({ commit, state }) {
    commit('setMobileMenuOpen', !state.mobileMenuVisible)
    commit('setBodyOverflow', state.mobileMenuVisible ? 'hidden' : 'auto')
  },
  closeMobileMenu({ commit }) {
    commit('setMobileMenuOpen', false)
  },

  clearUserError({ commit }) {
    commit('user/setError', null, { root: true })
  },
  //  openModal(state, name) {
  //     state[`${name}ModalVisible`] = true;
  //     state.bodyOverflow = 'hidden';
  //   },
  //   closeModal(state, name) {
  //     state[`${name}ModalVisible`] = false;
  //     state.bodyOverflow = 'auto';
  //   },
  closeAllModals({ commit }) {
    commit('resetState')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
