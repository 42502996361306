import axios from '@/axios'
import router from '@/router'
const state = {
  productDetailPageItem: null,
  similarProducts: null,
  error: null,
  isLoading: false
}

const getters = {
  productDetailPageItem: (state) => state.productDetailPageItem,
  similarProducts: (state) => state.similarProducts,
  error: (state) => state.error,
  isLoading: (state) => state.isLoading
}

const mutations = {
  setProductDetailPageItem(state, payload) {
    state.productDetailPageItem = payload
  },
  setSimilarProducts(state, payload) {
    state.similarProducts = payload
  },
  setError(state, payload) {
    state.error = payload
  },
  setLoading(state, payload) {
    state.isLoading = payload
  }
}

const actions = {
  async getProductDetailPageContent({ commit, rootGetters }, itemId) {
    commit('setLoading', true)
    try {
      const currencyCode = rootGetters['app/currencyCode']
      const { data } = await axios.get(`${process.env.VUE_APP_API}items/${itemId}`, {
        params: {
          currency: currencyCode
        }
      })

      commit('setProductDetailPageItem', data.payload)
      commit('setSimilarProducts', data.payload.similar)
      commit('setError', null)
    } catch (error) {
      if (error.response && error.response.data.status === 'ERROR') {
        commit('setError', {
          type: 'not-found',
          message: error.response.data.message
        })
      } else if (error.response && error.response.status === 401) {
        commit('setError', {
          type: 'unauthorized',
          message: 'You are not authorized to access this product'
        })
      } else {
        commit('setError', {
          type: 'general',
          message: 'An error occurred while loading the product'
        })
      }
    }
    commit('setLoading', false)
  },
  gotoItem({ commit }, id) {
    commit('setError', null)
    router.push({ name: 'ProductDetailPage', params: { id } })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
