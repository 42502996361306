export default {
    bind: function(el, binding) {
        el.clickOutsideEvent = function(event) {
          // Check that click was outside the el and event is not a bubbled event
          if (!(el == event.target || el.contains(event.target))) {
            // Call method provided in attribute value
            binding.value(event);
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind: function(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
}
