<template>
  <div class="modal sign-modal login-modal">
    <div class="overlay" @click="closeLoginModal"></div>
    <div class="wrapper">
      <div class="form">
        <div class="form-wrapper">
          <img class="close" @click="closeLoginModal" src="@/assets/icons/close.svg" />
          <div class="form-fields login-content">
            <div class="form-fields-wrapper">
              <div class="title small">{{ $t('LOGIN TO YOUR ACCOUNT') }}</div>
              <button class="button steam" @click="redirectToSteamAuth">
                <img src="@/assets/icons/steam.svg" alt="" class="steam-icon" />
                <span>{{ $t('Login with Steam') }}</span>
              </button>
              <div class="or-separator">
                <div class="or">{{ $t('or') }}</div>
              </div>
              <FloatingLabelInput type="email" :placeholder="$t('Email')" label="E-mail" v-model="email" />
              <FloatingLabelInput type="password" :placeholder="$t('Password')" label="Password" v-model="pass" />
              <div class="cta-container">
                <div class="forgot-pass-button" @click="switchToForgotPassword">{{ $t('Forgot password')
                  }}
                </div>
                <button class="button" @click="submitLogin">
                  <span>{{ $t('LOGIN WITH E-MAIL') }}</span>
                </button>
              </div>

              <transition name="fade">
                <div v-if="$parent.error" class="error-desc desc red">{{ $parent.error }}</div>
              </transition>
            </div>
            <div class="modal-bottom">
              <div class="desc">{{ $t("Don't have account") }}?</div>
              <div class="link" @click="$parent.openRegistrationModal">{{ $t('Create new') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'LoginModal',
  props: {
  },
  components: {
  },
  data: function () {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: '',
      pass: ''
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions('modals', ['openForgotPasswordModal', 'closeLoginModal']),
    switchToForgotPassword() {
      this.$parent.clearError();
      this.openForgotPasswordModal();
    },
    redirectToSteamAuth() {
      window.location.href = `${process.env.VUE_APP_DOMAIN}auth/steam/redirect`;
    },
    submitLogin() {
      let loginData = {
        username: this.email,
        password: this.pass
      };
      this.$emit('login', loginData);
    },
    submitForgotPass() {
      let data = {
        email: this.email
      };
      this.$emit('forgotPass', data);
    }
  }
};
</script>


<style lang="scss" scoped>
.modal.sign-modal.login-modal {
  .overlay {
    // Styles for the overlay
  }

  .wrapper {

    // Styles for the wrapper
    .form {

      // Styles for the form
      .form-wrapper {

        // Styles for the form wrapper
        .close {
          // Styles for the close button
        }

        .form-fields {

          // Styles for the login content
          .form-fields-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;

            // Styles for the form fields wrapper
            .title {
              // Styles for the small title
              margin-bottom: 29px;
            }

            .button.steam {
              max-width: 304px;
              color: $text-color-secondary;
              border: 1px solid $button-bg-color;
              padding: 15px 70px;
              background-color: transparent;
              display: flex;
              gap: 12px;
            }

            .or-separator {
              margin: 26px 0;

              .or {
                font-size: 14px;
                font-weight: 70;
                color: #88868d;
              }
            }

            label+label {
              margin-top: 12px;
            }

            label,
            .cta-container {
              width: 100%;
              max-width: 304px;
            }

            .cta-container {
              align-items: flex-start;

              // Styles for the CTA container
              .forgot-pass-button {
                cursor: pointer;
                margin: 14px 0 26px 0;
                color: #4a88ff;
                font-size: 14px;
                display: inline-block;

                &:hover {
                  opacity: 0.7;
                }
              }

              .button {
                // Styles for the buttons
                width: 100%;
                max-width: 304px;
              }
            }

            .slide-enter-active,
            .slide-leave-active {
              // Styles for the slide transition
            }

            .error-desc.desc.red {
              // Styles for the error description
            }

            .resend-link-button {
              // Styles for the resend confirmation link button
            }
          }
        }

        .modal-bottom {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          margin-top: 42px;
          font-size: 14px;
          font-weight: 700;
          line-height: 1.5;

          .desc {
            color: #fff;
          }

          .link {
            cursor: pointer;
            color: #4a88ff;

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }
    }
  }
}
</style>