<template>
  <div class="header-container section">
    <header class="header">
      <div class="wrapper">
        <div class="left">
          <router-link to="/" class="logo">
            <img src="@/assets/icons/logo.svg" class="img" />
          </router-link>
          <router-link to="/products/all" class="nav__item">{{ $t(`BUY SKINS`) }}</router-link>

        </div>
        <div class="right">
          <div class="link-container">

            <div class="header-social">
              <a target="_blank" :href="link.link" v-for="link in $parent.socialLinks" :key="link.img" class="header-social-link">
                <img :src="link.img" :alt="link.link" />
              </a>
            </div>

            <!-- Cart icon -->
            <div v-if="$parent.isAuth" v-clickOutside="closeCartModal" class="header-cart">
              <button class="icon cart-button" @click="toggleCartModal">
                <img src="@/assets/icons/cart.svg" class="img" />
                <div v-if="cartContents.length <= 0 || !$parent.isAuth" class="text">{{ $t(`Cart`) }}</div>
                <div v-else class="text">Cart <span class="cart-indicator">({{ cartContents.length }})</span></div>
              </button>
              <transition name="fade">
                <CartModal v-show="cartModalVisible" @click.stop.prevent />
              </transition>
            </div>


            <div class="header-dropdown" v-if="$parent.isAuth" @click="toggleDropdown"
              v-clickOutside="closeHeaderDropdown">
              <div class="header-dropdown-box">
                <img :src="user.avatarMedium || require('@/assets/icons/default-profile-icon.svg')"
                  class="img profile" />
              </div>
              <div class="header-dropdown-box">
                <span class="header-dropdown-balance">{{ currencySymbol }} {{ userBalance }}</span>
                <img src="@/assets/icons/chevron-down.svg" alt="Chevron Down" class="header-dropdown-icon"
                  :class="{ 'rotated': showDropdown }" />
                <div class="header-dropdown-options" v-if="showDropdown">
                  <router-link to="/profile" class="header-dropdown-option">{{ $t(`PROFILE`) }}</router-link>
                  <button @click="openDepositModal" class="header-dropdown-option">{{ $t(`TOP UP BALANCE`) }}</button>
                  <button @click="logout" class="header-dropdown-option">{{ $t(`LOG OUT`) }}</button>
                </div>
              </div>
            </div>

            <button class="modal-open button nav__item login" @click="openLoginModal" v-if="!$parent.isAuth">
              {{ $t('Login') }}
            </button>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import CartModal from '@/components/modals/CartModal'
export default {
  name: 'Header',
  props: ['cartContents'],
  components: {
    CartModal,
  },
  data: function () {
    return {
      showDropdown: false
    }
  },
  beforeRouteLeave(to, from, next) {
    this.closeCartModal();
    next();
  },
  mounted() {
  },
  beforeUnmount() {
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('user', ['fullName']),
    // ...mapGetters('app', ['']),

    ...mapState('modals', ['cartModalVisible']),
    userBalance() {
      return this.user.balance ? `${this.user.balance.toFixed(2)}` : '0.00'
    },
    ...mapGetters('app', ['currencySymbol'])
  },
  methods: {
    ...mapActions('modals', ['openDepositModal', 'openCartModal', 'toggleCartModal', 'closeCartModal', 'openLoginModal']),

    ...mapMutations('app', ['setCurrency']),
    closeHeaderDropdown() {
      this.showDropdown = false
    },
    handleCurrencyChange(currencyCode) {
      this.setCurrency(currencyCode)
      this.showDropdown = false
    },

    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    openSaleSkinModal() {
      this.$emit('openSaleSkinModal')
    },
    logout() {
      this.$emit('logout')
    }
  }
}
</script>


<style lang="scss" scoped>
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: #131214;

  .header {
    width: 100%;
    display: flex;
    align-items: center;

    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 16px;

      .left {
        display: flex;
        align-items: center;
        gap: 40px;

        .logo {
          .img {
            width: 88px;
            height: 88px;
            display: block;

            &.mob {
              display: none;
              width: 45px;
            }
          }
        }
      }

      .right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;

        .link-container {
          display: flex;
          align-items: center;

          .header-social {
            display: flex;
            gap: 22px;

            &-link {
              img {
                width: 32px;
                height: 32px;
                display: block;
              }
            }
          }

          .header-cart {
            position: relative;
            margin: 0 18px 0 26px;

            .icon {
              padding: 15px 8px 15px 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              border: 1px solid $text-color-secondary;
              background: transparent;
              width: 98px;
              height: 48px;
              border-radius: 2px;
              gap: 6px;

              .text {
                color: $text-color-secondary;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                text-wrap: nowrap;
              }

              .img {
                width: 16px;
                height: 16px;
                top: 16px;
                left: 15px;
              }

              .cart-indicator {
                display: inline-block;
                color: #88868D;
                font-size: 14px;
                font-weight: 400;
              }
            }
          }


          .header-dropdown {
            display: flex;
            align-items: center;
            border-radius: 2px;
            background-color: #2B2A2C;
            border: 1px solid #626165;
            height: 48px;
            cursor: pointer;
            position: relative;

            .header-dropdown-box {
              display: flex;
              justify-content: center;
              align-items: center;

              &:first-child {
                background-color: #131214;
                border: 1px solid #90FC3C;
                border-radius: 2px;
                width: 50px;
                height: 48px;

                .img.profile {
                  max-width: 20px;
                  max-height: 20px;
                }
              }

              &:last-child {
                padding: 14px 18px 13px 12px;
                width: 125px;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
              }
            }

            &-balance {
              color: #fff;
              font-size: 14px;
              line-height: 1.5;
            }

            &-icon {
              position: absolute;
              top: 18px;
              right: 18px;
              width: 14px;
              height: 14px;
              filter: invert(39%) sepia(9%) saturate(145%) hue-rotate(214deg) brightness(94%) contrast(90%);

              &.rotated {
                transform: rotate(180deg);
                transition: transform 0.3s ease;
              }
            }

            &-options {
              position: absolute;
              top: 57px;
              left: 0;
              width: 100%;
              background-color: #626165;
              border: 1px solid #626165;
              border-radius: 4px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              z-index: 2;
            }

            &-option {
              color: #fff;
              font-size: 14px;
              line-height: 1.5;
              padding: 14px;
              width: 100%;
              background-color: transparent;
              text-transform: uppercase;
              border: none;
              border-radius: 4px;
              cursor: pointer;
              text-decoration: none;

              &:hover {
                background-color: #39383B;
              }
            }
          }

          .login {
            margin-left: 38px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {

  .header-container {
    .header {
      .wrapper {
        .left {
          gap: 10px;

          .logo {
            width: 44px;
          }

          .nav__item {
            display: none;
          }
        }

        .right {
          margin-right: 55px;

          .link-container {
            .header-cart {
            }

            .header-social {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .header-container {
    .header {
      .wrapper {
        .left {}

        .right {
          .link-container {

            .login.button {
              margin-left: auto;
              max-width: 150px;
            }

            .header-social {}

            .header-cart {

              margin: 0;

              .cart-button {
                display: none;
              }
            }

            .header-dropdown {
              .header-dropdown-box {
                &:last-child {
                  width: 105px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>