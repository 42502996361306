<template>
    <div class="modal trade-url-modal">
        <div class="overlay" @click="closeTradeUrlModal"></div>
        <div class="wrapper">
            <div class="form">
                <div class="form-wrapper">
                    <img class="close" @click="closeTradeUrlModal" src="@/assets/icons/close.svg" />
                    <div class="form-fields trade-url-content">
                        <div class="form-fields-wrapper">
                            <div class="title">{{ $t("Insert the trade url") }}</div>
                            <div class="desc">{{ $t("Enter your trade link") }}:</div>
                            <div class="cta-container">
                                <FloatingLabelInput type="text" :placeholder="$t('Trade URL')" :label="$t('Trade URL')"
                                    v-model="tradeUrl" />
                                <button class="button" @click="saveTradeUrl">
                                    <span>{{ $t("Save") }}</span>
                                </button>
                            </div>
                            <transition name="fade">
                                <div v-if="$parent.successMessage" class="desc green">{{ $parent.successMessage }}</div>
                            </transition>
                            <transition name="fade">
                                <div v-if="$parent.error" class="error-desc desc red">{{ $parent.error }}</div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'TradeUrlModal',
    props: {},
    components: {},
    data: function () {
        return {
            tradeUrl: '',
        };
    },
    mounted() { },
    methods: {
        ...mapActions('modals', ['closeTradeUrlModal']),
        saveTradeUrl() {
            let data = { tradeUrl: this.tradeUrl };
            this.$emit('saveTradeUrl', data);
        },
    },
};
</script>

<style lang="scss" scoped>
.modal.trade-url-modal {
    .overlay {
        // Styles for the overlay
    }

    .wrapper {
        padding: 76px 102px;

        .form {
            // Styles for the form

            .form-wrapper {
                display: flex;
                flex-direction: column;
                align-items: center;

                // Styles for the form wrapper

                .close {
                    // Styles for the close button
                }

                .form-fields.trade-url-content {
                    .form-fields-wrapper {
                        .title {
                            margin-bottom: 16px;
                            text-align: center;
                            text-transform: uppercase;
                        }

                        .desc {
                            color: #fff;
                            font-size: 14px;
                            line-height: 1.5;
                            text-align: center;
                            margin-bottom: 26px;
                        }

                        .cta-container {
                            // Styles for the CTA container
                            width: 100%;
                            max-width: 304px;
                            margin: 0 auto;

                            .button {
                                margin-top: 26px;
                                width: 100%;
                                max-width: 304px;

                            }
                        }

                        .fade-enter-active,
                        .fade-leave-active {
                            // Styles for the fade transition
                        }

                        .desc.green {
                            // Styles for the success message
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 460px) {
    .modal.trade-url-modal {
        .overlay {
            // Styles for the overlay
        }

        .wrapper {
            padding: 36px 51px;
        }
    }
}
</style>