<template>
  <main class="main page-inside payment-result-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">
            Thank you for your purchase! You will receive all skins files to your provided e-mail. In case of any
            questions, please contact us at support@{{ $parent.hostname }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
  import Vue from 'vue'
  export default {
    name: 'PaymentSuccessPage',
    props: [],
    components: {},
    mounted() {
      Vue.$toast.success('Your balance has been successfully topped up')
    },
    data: function () {
      return {}
    },
    methods: {}
  }
</script>
