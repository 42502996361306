import axios from '@/axios'
import i18n from '@/i18n'

const defaultCurrency = {
  code: process.env.VUE_APP_FALLBACK_CURRENCY_CODE,
  symbol: process.env.VUE_APP_FALLBACK_CURRENCY_SYMBOL,
  value: process.env.VUE_APP_FALLBACK_CURRENCY_VALUE
}

const parseLocalStorageItem = (key, fallbackValue) => {
  try {
    const item = localStorage.getItem(key)
    return item ? JSON.parse(item) : fallbackValue
  } catch (e) {
    return fallbackValue
  }
}

const state = {
  isLoading: false,
  languages: [],
  currentLanguage: localStorage.getItem('language') || '10',
  currencies: [],
  currency: parseLocalStorageItem('currency', defaultCurrency)
}

const getters = {
  currentLanguage: (state) => state.currentLanguage,
  currentLanguageName: ({ currentLanguage, languages }) => {
    if (currentLanguage) {
      const lang = languages.find((lang) => lang.id == currentLanguage)

      if (lang) {
        return lang.title
      }
    }

    return ''
  },
  currentLanguageCode: ({ currentLanguage, languages }) => {
    if (currentLanguage) {
      const lang = languages.find((lang) => lang.id == currentLanguage)
      if (lang) {
        return lang.code
      }
    }

    return ''
  },
  allLanguages: (state) => state.languages,
  currencies: (state) => state.currencies,
  currency: (state) => state.currency,
  currencySymbol: (state) => (state.currency ? state.currency.symbol : ''),
  currencyValue: (state) => (state.currency ? state.currency.value : 0),
  currencyCode: (state) => (state.currency ? state.currency.code : '')
}

const actions = {
  fetchLanguages: async ({ commit, dispatch, state: { currentLanguage } }) => {
    try {
      commit('setLoading', true)
      const {
        data: { status, payload }
      } = await axios.get(process.env.VUE_APP_API + 'languages')
      if (status === 'OK') {
        commit('setLanguages', payload)

        if (!currentLanguage && payload.length) {
          await dispatch('changeLocale', payload[0].id)
          // commit('setCurrentLanguage', payload[0].id);
        } else {
          await dispatch('changeLocale', localStorage.getItem('language'))
          // commit('setCurrentLanguage', localStorage.getItem('language'));
        }
      }
    } catch (e) {
      // console.log(e)
    } finally {
      commit('setLoading', false)
    }
  },
  changeLocale: async ({ commit, getters }, localeId) => {
    if (localeId) {
      commit('setCurrentLanguage', localeId)

      try {
        const { data } = await axios.get('/localizations', {
          params: {
            lang_id: localeId
          }
        })

        if (data) {
          let translations = {}

          if (Array.isArray(data) && data.length) {
            data.forEach((translation) => (translations[Object.keys(translation)[0]] = Object.values(translation)[0]))
          } else if (typeof data === 'object') {
            translations = data
          }

          const { currentLanguageCode } = getters

          i18n.setLocaleMessage(currentLanguageCode, translations)
          i18n.locale = currentLanguageCode
        }
      } catch (e) {
        // console.log(e)
      }
    }
  },
  fetchCurrencies: async ({ commit }) => {
    try {
      commit('setLoading', true)
      const {
        data: { status, payload }
      } = await axios.get(process.env.VUE_APP_API + 'currencies')
      if (status === 'OK') {
        const currencies = payload.map(({ code, symbol, value }) => ({ code, symbol, value }))
        commit('setCurrencies', currencies)

        // Check if there's a stored currency in localStorage
        let storedCurrency
        try {
          storedCurrency = JSON.parse(localStorage.getItem('currency'))
        } catch (e) {
          storedCurrency = null
        }

        if (storedCurrency && currencies.some(({ code }) => code === storedCurrency.code)) {
          commit('setcurrency', storedCurrency)
        } else {
          // Set the first currency as the currency
          commit('setcurrency', currencies.length ? currencies[0] : defaultCurrency)
        }
      }
    } catch (e) {
      console.error('Error fetching currencies:', e)
      commit('setCurrencies', [defaultCurrency])
      commit('setcurrency', defaultCurrency)
    } finally {
      commit('setLoading', false)
    }
  }
}

const mutations = {
  setLoading: (state, loading) => (state.isLoading = loading),
  setLanguages: (state, languages) => (state.languages = languages),
  setCurrentLanguage: (state, language) => {
    state.currentLanguage = language
    localStorage.setItem('language', language)
  },
  setCurrencies: (state, currencies) => (state.currencies = currencies),
  setcurrency: (state, currency) => {
    state.currency = currency
    localStorage.setItem('currency', JSON.stringify(currency))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
