<template>
    <div v-if="!isAuth" class="login-overlay">
        <div class="login-modal">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LoginOverlay',
    props: {
        isAuth: {
            type: Boolean,
            required: true
        },
        openLoginModal: {
            type: Function,
            required: true
        }
    }
}
</script>

<style scoped>
.login-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.login-modal {
    text-align: center;
}
</style>