import Vue from 'vue'
import { getCountries } from 'libphonenumber-js';

export function debounce(fn, delay) {
  var timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    var args = arguments
    var that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}
export function getExteriorFromTitle(title) {
  const exteriorRegex = /.*\(([^)]+)\)/
  const match = title.match(exteriorRegex)
  return match ? match[1] : 'Unknown'
}

export function getExteriorFromDescription(description) {
  const exteriorRegex = /Exterior (.*?)\sCollection/
  const match = description.match(exteriorRegex)
  return match ? match[1] : ''
}

export function getWeaponName(title) {
  const weaponRegex = /^(.*?)\s\|/
  const match = title.match(weaponRegex)
  if (match) {
    return match[1]
  } else {
    const fallbackRegex = /^[^(]*/
    const fallbackMatch = title.match(fallbackRegex)
    if (fallbackMatch) {
      return fallbackMatch[0]
    } else {
      return 'N/A'
    }
  }
}
export function getSkinName(title) {
  // const skinRegex = /\|(.+?)\s/
  const skinRegex = /\|\s(.+?)\s\(/
  const match = title.match(skinRegex)
  return match ? match[1] : 'Unknown'
}

export function copyToClipboard(text) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(
      () => {
        Vue.$toast.success('Text copied to clipboard', {
          timeout: 2000
        })
      },
      () => {
        Vue.$toast.error('Failed to copy text', {
          timeout: 2000
        })
      }
    )
  } else {
    // Fallback to older clipboard API
    try {
      const textArea = document.createElement('textarea')
      textArea.value = text
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)
      Vue.$toast.success('Text copied to clipboard', {
        timeout: 2000
      })
    } catch (err) {
      Vue.$toast.error('Failed to copy text', {
        timeout: 2000
      })
    }
  }
}

export function formatDate(dateString) {
  if (typeof dateString === 'string') {
    // Handle the "2024-04-03T18:59:50.000000Z" format
    return new Date(dateString).toLocaleString()
  } else if (typeof dateString === 'number') {
    // Handle the 1713696302 format
    return new Date(dateString * 1000).toLocaleString()
  } else {
    return ''
  }
}

// formatDate(num) {
//   let objectDate = new Date(num)
//   let day = objectDate.getDate()
//   if (day < 10) {
//     day = '0' + day
//   }
//   let month = objectDate.getMonth() + 1
//   if (month < 10) {
//     month = `0${month}`
//   }
//   let year = objectDate.getFullYear()

//   function padTo2Digits(x) {
//     return String(x).padStart(2, '0')
//   }
//   let hoursAndMinutes = padTo2Digits(objectDate.getHours()) + ':' + padTo2Digits(objectDate.getMinutes())
//   let format = day + '.' + month + '.' + year + ' ' + hoursAndMinutes

//   return format
// }

export function generateSlug(text) {
  return text
    .toString() // Cast to string (optional)
    .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/_/g, '-') // Replace _ with -
    .replace(/-+/g, '-') // Replace multiple - with single -
    .replace(/^-|-$/g, '') // Remove leading and trailing -
}

/**
 * Returns a list of supported country ISO codes from the libphonenumber-js library.
 * This is used to filter the countries provided by the API to ensure compatibility
 * with the vue-phone-number-input component, avoiding errors due to unsupported countries.
 */
export function getSupportedCountryCodes() {
  return getCountries();
}