<template>
  <div class="carousel-product-card">
    <ProductCard :item="item" :sliderIsClickble="sliderIsClickble" :tag="tag" class="carousel-product-card__inner" />
  </div>
</template>

<script>
import ProductCard from './ProductCard.vue'

export default {
  name: 'CarouselProductCard',
  components: {
    ProductCard
  },
  props: {
    item: Object,
    sliderIsClickble: Boolean,
    tag: String,
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.carousel-product-card {
  padding: 0 10px;
  &__inner {
    flex: 1;
    display: flex;
    flex-direction: column;

    ::v-deep .product-card {
      &__wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 26px 20px 16px 16px;

        .product-card__title {
          margin-top: 16px;
        }

        .product-card__price-container {
          margin-top: 12px;
        }
      }

      &__image-container {
        width: 145px;
        height: 109px;
      }

      &__add-to-cart-container {
        display: none;
      }
    }
  }
}
</style>