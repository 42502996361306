<template>
  <main class="main product-page page-inside">
    <div class="main-wrapper">

      <Breadcrumbs :items="breadcrumbItems" />

      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <transition name="fade">
            <div class="product-preview" v-if="!isLoading && productDetailPageItem">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="product-details">
                    <div class="expand-icon" @click="showModal = true">
                      <img src="@/assets/icons/expand.svg" class="icon-expand" alt="">
                    </div>
                    <div class="left-block">
                      <div class="image-container">
                        <img :src="$parent.imgDomain + productDetailPageItem.img_url" class="img" />
                      </div>
                      <div class="price-block">
                        <div class="price-and-steam">
                          <div class="price">{{ currencySymbol }} {{ productDetailPageItem.price }}</div>
                          <div class="steam-price">
                            <span class="label">{{ $t(`Steam:`) }}</span>
                            <span class="value">$ 12658.79</span>
                          </div>
                        </div>
                        <div class="actions">
                          <div @click="addToCart('checkout')" class="button buy-now">{{ $t(`Buy Now`) }}</div>
                          <div class="add-to-cart-btn" @click="addToCart">
                            <img src="@/assets/icons/plus-icon.svg" class="icon-plus" alt="">
                            <img src="@/assets/icons/cart.svg" class="icon-cart" alt="">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="separator"></div>
                    <div class="right-block">
                      <div class="title">{{ productDetailPageItem.title }}</div>
                      <div class="info-block">
                        <div class="info-row">
                          <div class="label">{{ $t(`Type:`) }}</div>
                          <div class="value">{{ productDetailPageItem.type }}</div>
                        </div>
                        <div class="info-row">
                          <div class="label">{{ $t(`Rarity:`) }}</div>
                          <div class="value">{{ productDetailPageItem.quality }}</div>
                        </div>
                        <div class="info-row" v-if="productDetailPageItem.category == 'cs2'">
                          <div class="label">{{ $t(`Exterior:`) }}</div>
                          <div class="value">{{ getExteriorFromDescription(productDetailPageItem.description) ||
        getExteriorFromTitle(productDetailPageItem.title) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="error">
              <p>{{ error.message }}</p>
            </div>

            <Spinner v-else />
          </transition>
        </div>
      </section>
    </div>

    <div class="modal" v-if="showModal" @click.self="showModal = false" @keydown.esc="showModal = false" tabindex="0"
      ref="modal">
      <div class="modal-content">
        <v-zoomer :limitTranslation="false">
          <img :src="$parent.imgDomain + productDetailPageItem.img_url" class="modal-img" />
        </v-zoomer>
        <div class="close-icon" @click="showModal = false">
          <i class="icon-close"></i>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueZoomer from 'vue-zoomer'

import Spinner from '@/components/shared/Spinner.vue';
import { getExteriorFromDescription, getExteriorFromTitle, generateSlug } from '@/utils/helpers'
import Breadcrumbs from '../components/shared/Breadcrumbs.vue';
export default {
  name: 'ProductDetailPage',
  props: {
    id: {
      required: true
    }
  },
  components: {
    Spinner,
    Breadcrumbs,
    VZoomer: VueZoomer.Zoomer,
  },
  data: function () {
    return {
      showModal: false,
      productId: null,

      breadcrumbItems: [
        { label: 'Main', to: '/', key: 'main' },
        { label: 'Buy skins', to: '/products/all', key: 'buy-skins' },
      ],
    }
  },
  computed: {
    ...mapGetters('app', ['currencySymbol']),

    ...mapGetters('productDetail', ['productDetailPageItem', 'similarProducts', 'error', 'isLoading']),

    updatedBreadcrumbItems() {
      return [
        { label: 'Main', to: '/' },
        { label: 'Buy skins', to: '/products/all' },
        { label: this.productDetailPageItem.category, to: { name: 'ProductListingPage', params: { itemCategory: this.productDetailPageItem.category } }, key: 'category' },
        { label: this.productDetailPageItem.type, to: { name: 'ProductListingPage', params: { itemCategory: this.productDetailPageItem.category, itemType: this.productDetailPageItem.type } }, key: 'type' },
        { label: this.productDetailPageItem?.title, to: { name: 'ProductDetailPage', params: { id: this.id } }, key: 'title' },
      ];
    },
  },
  created() {
    if (this.id) {
      this.getProductDetailPageContent(this.id);
    }
  },
  mounted() {
    if (this.$refs.modal) {
      this.$refs.modal.focus();
    }
  },
  watch: {
    currencySymbol() {
      this.getProductDetailPageContent(this.id);
    },
    productDetailPageItem() {
      this.breadcrumbItems = this.updatedBreadcrumbItems;
    },
    showModal(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.modal.focus();
        });
      }
    },

  },
  methods: {
    ...mapActions('productDetail', ['getProductDetailPageContent']),
    generateSlug,
    getExteriorFromDescription,
    getExteriorFromTitle,
    addToCart(state) {
      this.$parent.addToCartHandler(this.id, state)
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-content {
  .vue-zoomer {
    overflow: visible;
  }
}

.main-wrapper {
  min-height: 70vh;
}

.product-preview {
  width: 100%;
}

.product-details {
  display: flex;
  margin: 24px 0 152px 0;
  background-color: #2B2A2C;
  padding: 38px 74px 30px;
  position: relative;
}

.left-block {

  .image-container {
    width: 324px;
    height: 256px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .price-block {
    display: flex;
    align-items: center;
    margin-top: 10px;
    justify-content: space-between;
    gap: 17px;
    padding: 8px 8px 8px 14px;
    background-color: #39383B;
    border-radius: 2px;

    .price-and-steam {
      display: flex;
      flex-direction: column;

      .price {
        color: $text-color-secondary;
        font-size: 16px;
        font-weight: 700;
      }

      .steam-price {
        text-wrap: nowrap;
        margin-top: 2px;
        display: none;

        .label {
          color: #88868D;
          font-size: 14px;
          margin-right: 4px;
          line-height: 1.5;
        }

        .value {
          color: #fff;
          font-size: 14px;
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;

      .button {
        flex-grow: 1;
        margin-right: 4px;
        width: 127px;
        padding: 16px 30px;
        height: 52px;
        background-color: transparent;
        color: $text-color-secondary;
        font-size: 14px;
        font-weight: 700;
        border: 1px solid $input-field-border-color;

        &:hover {
          opacity: 0.4;
        }
      }

      .add-to-cart-btn {
        border-radius: 2px;
        width: 52px;
        height: 52px;
        background-color: $button-bg-color;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #000;

        &:hover {
          opacity: 0.4;
        }

        .icon-plus,
        .icon-cart {
          filter: brightness(0%);
        }

        .icon-plus {
          width: 10px;
          height: 10px;
          margin-right: 6px;
        }
      }
    }
  }
}


.product-details .separator {
  width: 1px;
  background-color: #ccc;
  margin: 0 40px;
}

.product-details .right-block {
  flex-grow: 1;
}

.product-details .title {
  font-size: 32px;
  font-weight: 700;
}

.product-details .info-block {
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.product-details .info-row {
  display: flex;
  padding: 16px 20px;
  justify-content: space-between;
  background-color: #39383B;
  height: 53px;
}

.product-details .info-row .label {
  font-size: 14px;
  line-height: 1.5;
  color: #88868D;
  text-transform: uppercase;
  margin-right: 10px;
}

.product-details .info-row .value {
  font-size: 14px;
  line-height: 1.5;
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
}

.preview {
  position: relative;
}

.expand-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.expand-icon .icon-expand {
  color: #fff;
  font-size: 18px;
}

.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
}

.modal-img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.close-icon {
  position: fixed;
  top: 40px;
  right: 40px;
  width: 30px;
  height: 30px;
  // background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &::before {
    content: 'X';
    font-weight: 700;
    font-size: 36px;
  }
}

@media (max-width: 768px) {
  .product-details {
    flex-direction: column;
    padding: 20px;
  }

  .product-details .left-block {
    flex-basis: auto;
  }

  .product-details .image-container {
    width: 100%;
    height: auto;
  }

  .product-details .separator {
    width: 100%;
    height: 1px;
    margin: 20px 0;
  }
}



@media (max-width: 460px) {
  .left-block {
    .price-block {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
</style>