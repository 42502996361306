<template>
    <div class="app-language" v-clickOutside="closeDropdown">
        <div class="app-language-box">
            <div class="app-language-symbol">{{ currentLanguageCode }}</div>
        </div>
        <div class="app-language-box" @click="toggleLanguageDropdown">
            <span class="app-language-label">{{ $t('Language:') }}</span>
            <div class="app-language-dropdown" :class="{ 'open': isLanguageDropdownOpen }">
                <span class="app-language-value">{{ currentLanguageName }}</span>
                <img src="@/assets/icons/chevron-down.svg" alt="Chevron Down" class="app-language-icon"
                    :class="{ 'active': isLanguageDropdownOpen }" />
                <div class="app-language-options" v-if="isLanguageDropdownOpen">
                    <div class="app-language-option" v-for="language in allLanguages" :key="language.id"
                        @click="selectLanguage(language)">
                        {{ language.title }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
    name: "AppLanguageDropdownMenu",
    props: [],
    data() {
        return {
            isLanguageDropdownOpen: false,
        };
    },
    mounted() {
        this.fetchLanguages();
    },
    computed: {
        ...mapGetters('app', ['allLanguages', 'currentLanguage', 'currentLanguageName', 'currentLanguageCode']),
    },
    methods: {
        ...mapActions('app', ['fetchLanguages', 'changeLocale']),
        ...mapMutations('app', ['setCurrentLanguage']),
        toggleLanguageDropdown() {
            this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen;
        },
        closeDropdown() {
            this.isLanguageDropdownOpen = false;
        },
        selectLanguage(language) {
            if (language.id !== this.currentLanguage) {
                this.setCurrentLanguage(language.id);
                this.changeLocale(language.id);
                this.isLanguageDropdownOpen = false;
            } else {
                this.isLanguageDropdownOpen = false;
            }
        },
    },
};
</script>

<style lang="scss">
.app-language {
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: #39383b;
    border: 1px solid #626165;
    height: 50px;
    max-width: 202px;
    width: 100%;
    flex: 0 1 auto;


    &-box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;

        &:first-child {
            background-color: #626165;
            border-radius: 4px;
            width: 50px;
            height: 50px;
            flex: 0 0 50px;
        }

        &:last-child {
            border-radius: 0px;
            position: relative;
            flex: 1 1 152px;
            flex-direction: column;
            justify-content: center;
            padding: 0 18px 9px 14px;
            align-items: flex-start;
            cursor: pointer;
        }
    }

    &-symbol {
        color: $text-color-secondary;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5;
    }

    &-label {
        color: $text-color-description;
        font-size: 12px;
        line-height: 1.5;
    }

    &-value {
        color: #fff;
        font-size: 14px;
        line-height: 1.5;
    }

    &-icon {
        position: absolute;
        top: 18px;
        right: 18px;
        width: 14px;
        height: 14px;
        filter: invert(39%) sepia(9%) saturate(145%) hue-rotate(214deg) brightness(94%) contrast(90%);

        &.active {
            transform: rotate(180deg);
            filter: invert(20%) sepia(62%) saturate(551%) hue-rotate(40deg) brightness(109%) contrast(105%);
        }
    }

    &-dropdown {
        &.open {
            // background-color: #626165;
            // border-radius: 4px 4px 0 0;
        }

        .app-language-options {
            position: absolute;
            top: 50px;
            left: 0;
            right: 0;
            background-color: #39383b;
            border: 1px solid #626165;
            border-top: none;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
            padding: 8px 0;
            z-index: 10;

            .app-language-option {
                padding: 8px 16px;
                color: #fff;
                font-size: 14px;
                line-height: 1.5;
                cursor: pointer;

                &:hover {
                    background-color: #626165;
                }
            }
        }
    }
}
</style>